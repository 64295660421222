import {AfterViewInit, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/shared-services/authentication.service';
import { UserService } from 'src/app/shared-services/userservice.service';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { ShareDocumentsService } from 'src/app/shared-services/share-documents.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidationService } from 'src/app/authentication/services/validation.service';
import { MatDialog } from '@angular/material/dialog';

@Component({selector: 'app-navigation', templateUrl: './navigation.component.html',
styleUrls:['./navigation.component.scss']})
export class NavigationComponent implements OnInit {
  // Step 1:
  // Create a property to track whether the menu is open.
  // Start with the menu collapsed so that it does not
  // appear initially when the page loads on a small screen!
  public isMenuCollapsed = true;
  resetDisplyed = false;
  loginStatus$: Observable<boolean>;
  UserName$: Observable<string>;
  caregiverLogin$:Observable<boolean>;
  adminLogin$:Observable<boolean>;
  recruiterLogin$:Observable<boolean>;
  isSelectImage:boolean;
  username;
  firstname;
  lastname;
  fullname;
  Agencyname;
  phoneNumber;
  loginToken:any;
  userImage;
  public userInfo:boolean;
  userDetails:string;
  public updateCaregiverForm:FormGroup;
  public isProfile:boolean = false; /** to open user profile */
  //public dateObj;
  public updateRecruiterForm:FormGroup;
  //updaterecruiter:boolean;
  //updatecaregiver:boolean;
  userIcon;
  userId;
public alert ;
public caregiverAlert;
isAlert:boolean;
isCaregiverAlert:boolean;
public openCaregiver:boolean;
caregiver;
post;
recruiter;
selectedFile;
private badgeInterval
routeSubscription: Subscription;
public isAndroid = /Android/.test(window.navigator.userAgent);
public isIos;
@ViewChild("Caregiver") Caregiver:TemplateRef<any>;
 @ViewChild("Recruiter") Recruiter:TemplateRef<any>;
 @ViewChild("openImage") openImage:TemplateRef<any>;
 @ViewChild("openPopUp") openPopUp:TemplateRef<any>;

 //@ViewChild('caregiverDetails') caregiverDetails:TemplateRef<any>;
 //@ViewChild('recruiterDetails') recruiterDetails:TemplateRef<any>;
  constructor(private userService:UserService,
     private authenticationService:AuthenticationService,
     private router:Router,
     private shareDocumentsService:ShareDocumentsService,
     private validationService:ValidationService,
     private formBuilder:FormBuilder,
     private dialog:MatDialog,
     private route:ActivatedRoute){    
          if(localStorage.getItem('Authority') === "CARE_GIVER" || localStorage.getItem('Authority') === "RECRUITER" || localStorage.getItem('Authority') === "ADMIN"){
        this.userService.closeUserDetails.subscribe(()=>{
          this.isProfile = false;
        })
        //document.body.style.backgroundImage = 'none';
       }
       if((window.navigator.userAgent).includes('iPhone') || (window.navigator.userAgent).includes('iPad') || (window.navigator.userAgent).includes('iPod')){
         this.isIos = true;
      }
      else {
        this.isIos = false;
      }
  }
  ngOnInit(){
    this.routeSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
          this.isProfile = false;
          sessionStorage.removeItem('sentData');
      }
  });
    //this.getUserProfile();
    this.loginStatus$ = this.authenticationService.isLoggedIn;
   this.caregiverLogin$ = this.authenticationService.isCaregiver;
   this.recruiterLogin$ = this.authenticationService.isRecruiter;
   this.adminLogin$ = this.authenticationService.isAdmin;
   this.shareDocumentsService.badgeCount.subscribe(()=>{
    this.shareDocumentsService.badgeCounts()
    .subscribe((res)=>{
     // console.log('res', res);
      this.alert = res.body.recruiterDocPermissionCount;
      if(this.alert==0){
        this.isAlert = false;
      }else{
        this.isAlert= true;
      }
      this.caregiverAlert = res.body.nurseFileRequestsCount;
      if(this.caregiverAlert==0){
        this.isCaregiverAlert = false;
      }else{
        this.isCaregiverAlert= true;
      }
      setTimeout(()=>{
        this.isAlert = false;
        this.isCaregiverAlert= false;
     }, 10000);
        return res;
    },
    (err)=>{
    // console.log('err', err);
    }
    )
   })
   this.authenticationService.profileImage.subscribe(()=>{
    this.getUserProfile();
   })
   if(localStorage.getItem('Authority') === "CARE_GIVER" || localStorage.getItem('Authority') === "RECRUITER"){
    this.getUserProfile();
    this.badgeCount();
    //document.body.style.backgroundImage = 'none';
   }
   else if(localStorage.getItem('Authority') === "ADMIN") {
    this.getUserProfile();
  //  document.body.style.backgroundImage ="url('../../../assets/shutterstock_331019402-1.jpg')";
   }
 //  this.getUserProfile();
   this.createupdateCaregiverForm();
   this.createupdateRecruiterForm();
  // document.body.style.backgroundImage = 'none';
  // this.shareDocumentsService.alertEvent();
 this.shareDocumentsService.alertData.subscribe(()=>{
   this.badgeCount();
   this.getUserProfile();
   document.body.style.backgroundImage = 'none';
   if(localStorage.getItem('Authority') === "CARE_GIVER") {
    this.caregiver = true;
    this.recruiter = false;
   
  }
  else if(localStorage.getItem('Authority') === "RECRUITER") {
    this.recruiter = true;
    this.caregiver = false;
  }
 })
  
   
}
ngAfterViewInit(): void {
let clickBtn = document.getElementById('clickBtn');
    document.onclick = (args: any) : void => {
      if(args.target.id !== "clickBtn" ){
        this.openCaregiver = false;
      }
      //args.target.parentNode.className != 'caregiverdetails userdetails'
    }
  

  
 
}
goToProfileScreen(){
  this.router.navigateByUrl('./profile');
    this.router.navigate(['./profile'], { relativeTo:this.route});
  this.isProfile = false;
  this.userService.goToProfile();
}
openProfile() {
this.isProfile = !this.isProfile;
}
openImagedia() {
  this.isProfile = false;
this.dialog.open(this.openImage,  {panelClass: 'my-custom-dialog-class_1'});
}
routeToHome(){
  if(localStorage.getItem('Authority') === "CARE_GIVER"){
    this.router.navigateByUrl('/caregiverhome');
    this.router.navigate(['/caregiverhome'], { relativeTo:this.route})
  }else if(localStorage.getItem("Authority") === "RECRUITER"){
    this.router.navigateByUrl('/recruiteraccess-documents');
    this.router.navigate(['/recruiteraccess-documents'], { relativeTo:this.route})
  }
  else if(localStorage.getItem("Authority") === "ADMIN"){
    this.router.navigateByUrl('/admin-main-page');
    this.router.navigate(['/admin-main-page'], { relativeTo:this.route})
  }else{
  this.router.navigateByUrl('');
    this.router.navigate([''], { relativeTo:this.route})
  }
}
login(){
  this.isMenuCollapsed = true
  this.router.navigateByUrl('./login');
    this.router.navigate(['./login'], { relativeTo:this.route});
}
registration(){
  this.isMenuCollapsed = true
  this.router.navigateByUrl('./registration');
    this.router.navigate(['./registration'], { relativeTo:this.route})
}
installApp(){
  if (this.isAndroid) {
    window.location.href = "https://play.google.com/store/apps/details?id=com.genieapp";
    //window.location.href = $link.attr('genie://app.com/');
   // window.location :'';
   /* if (document.hasFocus()) {
      setTimeout("window.location = 'https://play.google.com/store/apps/details?id=com.genieapp';", 2000);
    }*/
  }
  
  if (this.isIos) {
                  
      window.location.href = 'https://geniehealthcare.page.link/shareapp';
    
  }
}
editProfile(){
  //  this.updatecaregiver = true;
  if(this.caregiver){
    this.dialog.open(this.Caregiver);
  }
    if(this.recruiter){
      this.dialog.open(this.Recruiter);
    }
  }
  createupdateCaregiverForm() {
    this.updateCaregiverForm = this.formBuilder.group({
     // role: 'CARE_GIVER',
      firstName: ['', [Validators.required, Validators.max(13)]],
      lastName: ['', [Validators.required, Validators.max(13)]],
      username: ['', [Validators.required, Validators.email, Validators.pattern(this.validationService.emailregex)], this.validationService.checkInUseEmail],
      phoneNumber: ['', [Validators.required, Validators.pattern(this.validationService.phoneregex), this.validationService.checkPhoneNumber]],
      //enableEmailNotif:['', [Validators.required]],
     // enableMobileNotif:['', [Validators.required]],

    });
  
    }
  createupdateRecruiterForm(){
  this.updateRecruiterForm = this.formBuilder.group({
  role: 'RECRUITER',
  firstName: ['', [Validators.required, Validators.max(13)]],
  lastName: ['', [Validators.required, Validators.max(13)]],
  username: ['', [Validators.required, Validators.email, Validators.pattern(this.validationService.emailregex)], this.validationService.checkInUseEmail],
  phoneNumber: ['', [Validators.required, Validators.pattern(this.validationService.phoneregex), this.validationService.checkPhoneNumber]],
  agencyName: ['', Validators.required ],
  enableEmailNotif:['', [Validators.required]],
  enableMobileNotif:['', [Validators.required]],
    })
  }
getErrorEmail() {
  return this.updateCaregiverForm.get('username').hasError('required') ? 'Email is required' :
    this.updateCaregiverForm.get('username').hasError('pattern') ? 'Not a valid email address' : '';
     // this.caregiverForm.get('email').hasError('alreadyInUse') ? 'This email address is already in use' : '';
}
getErrorPhone() {
  return this.updateCaregiverForm.get('phoneNumber').hasError('required') ? 'Phone number is required' :
    this.updateCaregiverForm.get('phoneNumber').hasError('pattern') ? 'Not a valid phone number (Ex. 111 111 1111)' : '';
     // this.caregiverForm.get('email').hasError('alreadyInUse') ? 'This email address is already in use' : '';
}
getErroremail() {
  return this.updateRecruiterForm.get('username').hasError('required') ? 'Email is required' :
    this.updateRecruiterForm.get('username').hasError('pattern') ? 'Not a valid email address' : '';
     // this.caregiverForm.get('email').hasError('alreadyInUse') ? 'This email address is already in use' : '';
}
getErrorphone() {
  return this.updateRecruiterForm.get('phoneNumber').hasError('required') ? 'Phone number is required' :
    this.updateRecruiterForm.get('phoneNumber').hasError('pattern') ? 'Not a valid phone number (Ex. 111 111 1111)' : '';
     // this.caregiverForm.get('email').hasError('alreadyInUse') ? 'This email address is already in use' : '';
}
getUserProfile(){
  this.userService.UserProfile()
  .subscribe((res)=>{
    if(res.body.hasImage == "NO"){
      this.isSelectImage = false;
    }
    else if(res.body.hasImage == "YES"){
      this.isSelectImage = true;
      this.userImage ='data:image/png;base64,' + res.body.userImage;
    }
    localStorage.setItem('username', res.body.username);
    localStorage.setItem('firstname', res.body.firstName);
    localStorage.setItem('lastname', res.body.lastName);
    localStorage.setItem('fullName', res.body.fullName);
    localStorage.setItem('agencyName', res.body.agencyName);
    
    this.username = localStorage.getItem('username');
    this.firstname = localStorage.getItem('firstname');
    this.lastname =localStorage.getItem('lastname');
    this.fullname = localStorage.getItem('fullName');
    this.Agencyname =  localStorage.getItem('agencyName');
    this.loginToken = res.body.userToken;
    this.phoneNumber = res.body.phoneNumber;
   
    this.userIcon = this.firstname.charAt(0) + this.lastname.charAt(0);
    if(localStorage.getItem('Authority') === "CARE_GIVER") {
      this.caregiver = true;
    this.updateCaregiverForm.controls['firstName'].setValue(this.firstname);
    this.updateCaregiverForm.controls['lastName'].setValue(this.lastname);
    this.updateCaregiverForm.controls['username'].setValue(this.username);
    this.updateCaregiverForm.controls['phoneNumber'].setValue(this.phoneNumber)
    }
    if(localStorage.getItem('Authority') === "RECRUITER") {
      this.recruiter = true;
    this.updateRecruiterForm.controls['firstName'].setValue(this.firstname);
    this.updateRecruiterForm.controls['lastName'].setValue(this.lastname);
    this.updateRecruiterForm.controls['username'].setValue(this.username);
    this.updateRecruiterForm.controls['phoneNumber'].setValue(this.phoneNumber)
    this.updateRecruiterForm.controls['agencyName'].setValue(this.Agencyname);
    }
  },
  (err)=>{
   /* if(err.status == 412) {
      this.userService.validateClient()
      .subscribe(res=>{
        console.log('res', res);
        this.userService.validationEvent();
this.router.navigateByUrl('/login');
this.router.navigate(['/login'], { relativeTo:this.route})
      })
    }*/
  }
  )
}
homePage(){
  this.shareDocumentsService.paginationEvent();
}
badgeCount(){

  this.shareDocumentsService.badgeCounts()
  .subscribe((res)=>{
    //console.log('res', res);
    if(res.status == 200 && localStorage.getItem('remember') === 'Yes' && localStorage.getItem('Authority') === 'CARE_GIVER' && sessionStorage.getItem('isNextTab') === null){
      this.router.navigateByUrl('/caregiverhome');
      this.router.navigate(['/caregiverhome'], { relativeTo:this.route});
    }
    else if(res.status == 200 && localStorage.getItem('remember') === 'Yes' && localStorage.getItem('Authority') === 'RECRUITER' && sessionStorage.getItem('isNextTab') === null){
      this.router.navigateByUrl('/recruiteraccess-documents');
      this.router.navigate(['/recruiteraccess-documents'], { relativeTo:this.route})
    }
    this.alert = res.body.recruiterDocPermissionCount;
    if(this.alert==0){
      this.isAlert = false;
    }else{
      this.isAlert= true;
    }
    this.caregiverAlert = res.body.nurseFileRequestsCount;
    if(this.caregiverAlert==0){
      this.isCaregiverAlert = false;
    }else{
      this.isCaregiverAlert= true;
    }
    setTimeout(()=>{
      this.isAlert = false;
   }, 10000);
      return res;
  },
  (err)=>{
   //console.log('err', err);
  }
  )
}
updateonSubmit(value){
  this.post = value;
  this.post.userToken = this.loginToken;
  if(this.post.enableEmailNotif==true){
    this.post.enableEmailNotif = "YES"
  }
  else{
   this.post.enableEmailNotif = "NO"
  }
  if(this.post.enableMobileNotif==true){
   this.post.enableMobileNotif = "YES"
 }
 else{
  this.post.enableMobileNotif = "NO"
 }
  //let userToken = localStorage.getItem('jwt');
this.userService.updateUserProfile(this.post).subscribe((res)=>{
this.getUserProfile();
})
}
openDetails(){
  this.openCaregiver = !this.openCaregiver;
  //localStorage.setItem('open', '1')
  if(this.openCaregiver){
    localStorage.setItem('open', '1')
  }
  else if(!this.openCaregiver){
    localStorage.setItem('open', '0');
  }
 // this.dialog.open(this.caregiverDetails);
// document.getElementById('caregiver').style.display ="block";
  
}
openrecruiterDetails(){
  //this.dialog.open(this.recruiterDetails);
}
  onlogOut(){
    this.isProfile = false;
   this.authenticationService.logout();

    this.userService.logout();
    this.userService.removeEvent();
    this.router.navigateByUrl('/login');
       this.router.navigate(['./login'], { relativeTo:this.route});
  }
  onSelectFile(event) { /** selecting image */
    let imageList:File = event.target.files;
    if(event.target.files && event.target.files[0]){
      const file = event.target.files[0];
      let formData:FormData = new FormData();
      formData.append('ImportFile', file);
      this.userService.uploadProfilePicture(formData).subscribe(
        (res) => {
          if(res){
          this.getUserProfile();
          }
         // console.log(res);
        },
        (err) => {
          if(err){
            this.getUserProfile();
            }
         // console.log(err);
        })
      
    }
      


  }
    /*if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      this.selectImage = true;
      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event) => { // called once readAsDataURL is completed
        this.url = event.target.result;
        this.userService.uploadProfilePicture(this.url).subscribe(response=>{
          console.log(response);
        })
      }*/
    
  
}
