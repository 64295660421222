<!--<div style="height: 630px;background-color: #2777BA;margin-left: auto;width:95%">
    <div style="display: flex;">
    <div style="background: white;
    margin: 10px;
    height: 600px;
    border-radius: 5px;">
        <ul style="list-style-type: none;padding:0;margin: 0;font-family: 'Century Gothic';">
            <li class="helpList" (click)="openUpload()" id="openUpload">
                About Upload document
            </li>
            <li class="helpList" id="openShare">
            About Share document
            </li>
            <li class="helpList" id="openAppRej">
            About Approve/reject document
            </li>
        </ul>
        </div>
        <div style="width:75%;margin:10px;color:white;" *ngIf="isOpenUpload">
            <h1>Steps for Upload documents
                </h1>
                <div style="padding-bottom: 10px;margin-bottom: 10px;">
                    <p class="steps" (click)="isStep1 = !isStep1">Step.1<i style="float: right;" *ngIf="!isStep1" class="fa fa-chevron-down"></i><i style="float: right;" *ngIf="isStep1" class="fa fa-chevron-up"></i></p>
                    <p *ngIf="isStep1"  style="font-size: 20px;">Go to caregiver home page and click on Upload document</p>
                    <div *ngIf="isStep1" style="text-align: center;">
                    <img *ngIf="isStep1" style="border: 1px solid white;" src="../../../assets/homescreen.jpg" width="400">
                    </div>
                    </div>
                    <div style="padding-bottom: 10px;margin-bottom: 10px;">
                        <p class="steps"  (click)="isStep2 = !isStep2">Step.2<i style="float: right;" *ngIf="!isStep2" class="fa fa-chevron-down"></i><i style="float: right;" *ngIf="isStep2" class="fa fa-chevron-up"></i></p>
                        <p *ngIf="isStep2" style="font-size: 20px;">Then you will get a pop-up like below.</p>
                                           <div *ngIf="isStep2" style="text-align: center;">
                        <img *ngIf="isStep2" style="border: 1px solid white;" src="../../../assets/pop-up.jpg" width="400">
                    </div>
                        </div>
                        <div style="padding-bottom: 10px;margin-bottom: 10px;">
                            <p class="steps" (click)="isStep3 = !isStep3">Step.3<i style="float: right;" *ngIf="!isStep3" class="fa fa-chevron-down"></i><i style="float: right;" *ngIf="isStep3" class="fa fa-chevron-up"></i></p>
                            <p *ngIf="isStep3" style="font-size: 20px;">In that pop-up select file and upload then start date, expiration dates of your uploaded file will populated like below image.
                                You can edit expiration and start dates if you think that populated dates are not correct. 
                                Next continue with filling other details like documentName, document Type and description and click on Add document
                                </p>
                                <div *ngIf="isStep3" style="text-align: center;">
                                <img *ngIf="isStep3" style="border: 1px solid white;" src="../../../assets/pop-up1.jpg" width="400">
                                </div>
                            </div>
            </div>
            </div>
    </div>-->
    <div id="helpScreen">
        <div style="color: #ffffff;padding: 10px 0 0 20px;display: flex;" *ngIf="isOpenUpload || isOpenShare || isOpenEdit || isOpenApp_Rej || isOpenProfile  || isOpenAcc || isOpenReq"><p class="homeClass" (click)="go_back()">HOME</p><span>>></span><p>{{present_text}}</p></div>
        <div *ngIf="!isOpenUpload && !isOpenShare && !isOpenEdit && !isOpenApp_Rej && !isOpenProfile && !isOpenAcc && !isOpenReq">
        <div style="color: white;
        text-align: center;
        /* margin: 30px; */
        padding: 10px;
        padding-top: 35px;">
        <p style="font-family: 'Century Gothic';
        /* font-weight: normal; */
        font-size: 25px;">How can we help you<span *ngIf="(loginStatus$ | async) as loginstatus">, {{username}}</span>?</p>
        <!--<p style="font-family: 'Century Gothic';">Choose a category to find the help you need</p>-->
        </div>
        <div style="display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;" *ngIf="(loginStatus$ | async) as loginstatus">
            <div *ngIf="caregiverLogin$ | async as caregiverLogin" class="HelpCenter" (click)="isOpenUpload_doc()">
                <p><i class="fa fa-upload"></i></p>

                <p class="text">
                    <span>How to</span>
                    <span>Upload documents</span>
                </p>
                </div>
                <div *ngIf="caregiverLogin$ | async as caregiverLogin" class="HelpCenter" (click)="isOpenShare_doc()">
                    <p><i class="fa fa-share"></i></p>
                    <p class="text">
                        <span>How to</span>
                        <span>Share documents</span>
                    </p>
                    </div>
                    <div *ngIf="caregiverLogin$ | async as caregiverLogin" class="HelpCenter" (click)="isOpenEdit_doc()">
                        <p><i class="fa fa-edit"></i></p>
                        <p class="text">
                            <span>How to</span>
                            <span>Edit uploaded documents</span>
                        </p>
                        </div>
                        <div *ngIf="caregiverLogin$ | async as caregiverLogin" class="HelpCenter" (click)="isOpenAPP_REJ()">
                            <p style="display: flex;justify-content: space-around;align-items: center;"><i class="fa fa-check-circle"></i><span>OR</span><i class="fa fa-times-circle"></i></p>
                            <p class="text">
                                <span>How to</span>
                                <span>Approve/Reject documents</span>
                            </p>
                            </div>
                            <div *ngIf="recruiterLogin$ | async as recruiterLogin" class="HelpCenter" (click)="isOpenAccess_doc()">
                                <p style="display: flex;justify-content: space-around;align-items: center;"><i class="fa fa-folder-open"></i></p>
                                <p class="text">
                                    <span>How to</span>
                                    <span>Access documents</span>
                                </p>
                                </div>
                                <div *ngIf="recruiterLogin$ | async as recruiterLogin" class="HelpCenter" (click)="isOpenReq_doc()">
                                    <p style="display: flex;justify-content: space-around;align-items: center;"><i class="fa fa-file"></i></p>
                                    <p class="text">
                                        <span>How to</span>
                                        <span>
                                        Request documents
                                        </span>
                                    </p>
                                    </div>
                                <div  *ngIf="(loginStatus$ | async) as loginstatus" class="HelpCenter" (click)="isOpenProfileSettings()">
                                    <p><i class="fa fa-cog"></i></p>
                                    <p class="text"> Profile Settings</p>
                                    </div>
            </div>
            <div *ngIf="!(loginStatus$ | async) as loginstatus" style="display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;">
                <div class="HelpCenter" (click)="isOpenUpload_doc()">
                    <p><i class="fa fa-upload"></i></p>
    
                    <p class="text">
                        <span>How to</span>
                        <span>Upload documents</span>
                    </p>
                    </div>
                    <div class="HelpCenter" (click)="isOpenShare_doc()">
                        <p><i class="fa fa-share"></i></p>
                        <p class="text">
                            <span>How to</span>
                            <span>Share documents</span>
                        </p>
                        </div>
                        <div class="HelpCenter" (click)="isOpenEdit_doc()">
                            <p><i class="fa fa-edit"></i></p>
                            <p class="text">
                                <span>How to</span>
                                <span>Edit uploaded documents</span>
                            </p>
                            </div>
                            <div class="HelpCenter" (click)="isOpenAPP_REJ()">
                                <p style="display: flex;justify-content: space-around;align-items: center;"><i class="fa fa-check-circle"></i><span>OR</span><i class="fa fa-times-circle"></i></p>
                                <p class="text">
                                    <span>How to</span>
                                    <span>Approve/Reject documents</span>
                                </p>
                                </div>
                                <div class="HelpCenter" (click)="isOpenAccess_doc()">
                                    <p style="display: flex;justify-content: space-around;align-items: center;"><i class="fa fa-folder-open"></i></p>
                                    <p class="text">
                                        <span>How to</span>
                                        <span>Access documents</span>
                                    </p>
                                    </div>
                                    <div class="HelpCenter" (click)="isOpenReq_doc()">
                                        <p style="display: flex;justify-content: space-around;align-items: center;"><i class="fa fa-file"></i></p>
                                        <p class="text">
                                            <span>How to</span>
                                            <span>
                                            Request documents
                                            </span>
                                        </p>
                                        </div>
                                        <div class="HelpCenter" (click)="isOpenProfileSettings()">
                                            <p><i class="fa fa-cog"></i></p>
                                            <p class="text"> Forgot password</p>
                                            </div>
                </div>
        </div>
        
        <!-- upload doc started-->
        <div class="openHelpScreens" *ngIf="isOpenUpload">
            <div style="display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;">
            <h1 style="font-family: 'Acumin Variable Concept';margin: 0;">STEPS FOR UPLOAD DOCUMENTS
                </h1>
           <button
           style="border: none;
       background: #AA004D;
       color: white;
       padding: 8px;
       border-radius: 4px;
       margin-left: 10px;
       outline: none;
       font-family: 'Century Gothic';"
       (click)="expandOrCollapse(isCollapse = !isCollapse, 'notProfile')"
           >
               {{buttonText}}
               </button>

           </div>
                <div style="padding-bottom: 10px;margin-bottom: 10px;">
                    <p class="steps" (click)="step('step1')" id="Step1"><i>Step.1</i><i *ngIf="!isStep1" class="fa fa-plus"></i><i *ngIf="isStep1" class="fa fa-minus"></i></p>
                    <div *ngIf="isStep1"  style="font-size: 18px;">
                        <p class="step_para"><span><i class="fa fa-angle-double-right"></i></span> 
                            <span>
                                Login to your caregiver account
                            </span>
                    </p>
                        <p class="step_para"><span><i class="fa fa-angle-double-right"></i></span> 
                            <span>
                                Click on the Open Folder Icon on the left side of the page
                        <!--Go to <a style="color: #ffffff;text-decoration: underline;" [routerLink]="'/caregiverhome'">topicujobs.com caregiverhome page</a> and click on Upload document.-->
                        </span>
                    </p>
                    <p class="step_para"><span><i class="fa fa-angle-double-right"></i></span> 
                        <span>
                            Click on "click here" link or Upload document button on the right side of the page.
                </span>
                </p>
                    </div>
                    <div *ngIf="isStep1" style="text-align: center;">
                    <img (click)="openImage('../../../assets/updated_uploadDoc_1.png')" *ngIf="isStep1" style="border: 1px solid white;cursor: pointer;" src="../../../assets/updated_uploadDoc_1.png" width="400">
                    </div>
                    </div>
                    <div style="padding-bottom: 10px;margin-bottom: 10px;">
                        <p class="steps"  (click)="step('step2')" id="Step2"><i>Step.2</i><i *ngIf="!isStep2" class="fa fa-plus"></i><i *ngIf="isStep2" class="fa fa-minus"></i></p>
                        <div *ngIf="isStep2" style="font-size: 18px;">
                            <p class="step_para"><span><i class="fa fa-angle-double-right"></i></span> 
                                <span>
                                    A pop up will appear.
                        </span>
                        </p>
                        </div>
                                           <div *ngIf="isStep2" style="text-align: center;">
                        <img (click)="openImage('../../../assets/updated_uploadDoc_2.png')" *ngIf="isStep2" style="border: 1px solid white;cursor: pointer;" src="../../../assets/updated_uploadDoc_2.png" width="400">
                    </div>
                        </div>
                        <div style="padding-bottom: 10px;margin-bottom: 10px;">
                            <p class="steps" (click)="step('step3')" id="Step3"><i>Step.3</i><i *ngIf="!isStep3" class="fa fa-plus"></i><i *ngIf="isStep3" class="fa fa-minus"></i></p>
                            <div *ngIf="isStep3" style="font-size: 18px;">
                                <p class="step_para"><span><i class="fa fa-angle-double-right"></i></span> 
                                    <span>
                                        Click Select File and upload your document.
                                </span>
                                </p>
                                <p class="step_para"><span><i class="fa fa-angle-double-right"></i></span> 
                                    <span>

                                        Here you need to give document related fields like document name, description.
                                        Start date and expiration dates are auto-populated. You can edit these dates as needed.
                                </span>
                                </p>
                                <p class="step_para"><span><i class="fa fa-angle-double-right"></i></span> 
                                    <span>
                                        Fill out the document details and click Add Document.
                                </span>
                                </p>
                                </div>
                                <div *ngIf="isStep3" style="text-align: center;">
                                <img (click)="openImage('../../../assets/updated_uploadDoc_3.png')" *ngIf="isStep3" style="border: 1px solid white;cursor: pointer;" src="../../../assets/updated_uploadDoc_3.png" width="400">
                                </div>
                            </div>
                            <div style="padding-bottom: 10px;margin-bottom: 10px;">
                                <p class="steps" (click)="step('step4')" id="Step4"><i>Step.4</i><i *ngIf="!isStep4" class="fa fa-plus"></i><i *ngIf="isStep4" class="fa fa-minus"></i></p>
                                <div *ngIf="isStep4" style="font-size: 18px;">
                                    <p class="step_para"><span><i class="fa fa-angle-double-right"></i></span> 
                                        <span>
                                            Done! You can now view your document in your account.
                                    </span>
                                    </p>
                                    </div>
                                    <div *ngIf="isStep4" style="text-align: center;">
                                    <img (click)="openImage('../../../assets/updated_uploadDoc_4.png')" *ngIf="isStep4" style="border: 1px solid white;cursor: pointer;" src="../../../assets/updated_uploadDoc_4.png" width="400">
                                    </div>
                                </div>
            </div>
                    <!-- upload doc ended-->
        <!-- Share doc started-->
            <div class="openHelpScreens" *ngIf="isOpenShare">
                <div style="display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 10px;">
                <h1 style="font-family: 'Acumin Variable Concept';margin: 0;">STEPS FOR SHARE DOCUMENTS
                    </h1>
               <button
               style="border: none;
           background: #AA004D;
           color: white;
           padding: 8px;
           border-radius: 4px;
           margin-left: 10px;
           outline: none;
           font-family: 'Century Gothic';"
           (click)="expandOrCollapse(isCollapse = !isCollapse, 'notProfile')"
               >
                   {{buttonText}}
                   </button>
    
               </div>
                <div style="padding-bottom: 10px;margin-bottom: 10px;">
                    <p class="steps" (click)="step('step1')" id="Step1"><i>Step.1</i><i *ngIf="!isStep1" class="fa fa-plus"></i><i *ngIf="isStep1" class="fa fa-minus"></i></p>
                    <div *ngIf="isStep1"  style="font-size: 18px;">
                        <p class="step_para"><span><i class="fa fa-angle-double-right"></i></span> 
                            <span>
                        <!--Go to <a style="color: #ffffff;text-decoration: underline;" [routerLink]="'/caregiverhome'">
                        topicujobs.com caregiverhome page</a> and select one or more documents then click on Share document.-->
                        Login to your Caregiver account and select the document(s) you want to share and click Share Document                        </span>
                        </p>
                        <p class="step_para"><span><i class="fa fa-angle-double-right"></i></span> 
                            <span>
                                For a single document, you can simply select the Share icon on the right side of the line under Actions.
                    </span>
                    </p>
                    </div>

                    <div *ngIf="isStep1" style="text-align: center;">
                    <img (click)="openImage('../../../assets/updated_shareDoc_1.png')" *ngIf="isStep1" style="border: 1px solid white;cursor: pointer;" src="../../../assets/updated_shareDoc_1.png" width="400">
                    </div>
                    </div>
                    <div style="padding-bottom: 10px;margin-bottom: 10px;">
                        <p class="steps"  (click)="step('step2')" id="Step2"><i>Step.2</i><i *ngIf="!isStep2" class="fa fa-plus"></i><i *ngIf="isStep2" class="fa fa-minus"></i></p>
                        <div *ngIf="isStep2" style="font-size: 18px;">
                            <p class="step_para"><span><i class="fa fa-angle-double-right"></i></span> 
                                <span>
                                 Searching for the recruiter you want to send your documents to with their name, email, or phone number.
                            </span>
                        </p>
                        </div>
                                           <div *ngIf="isStep2" style="text-align: center;">
                        <img (click)="openImage('../../../assets/updated_shareDoc_2.png')" *ngIf="isStep2" style="border: 1px solid white;cursor: pointer;" src="../../../assets/updated_shareDoc_2.png" width="400">
                    </div>
                        </div>
                        <div style="padding-bottom: 10px;margin-bottom: 10px;">
                            <p class="steps" (click)="step('step3')" id="Step3"><i>Step.3</i><i *ngIf="!isStep3" class="fa fa-plus"></i><i *ngIf="isStep3" class="fa fa-minus"></i></p>
                            <div *ngIf="isStep3" style="font-size: 18px;">
                                <p class="step_para"><span><i class="fa fa-angle-double-right"></i></span> 
                                    <span>
                                        Select your recruiter(s) and click on Send Documents button.
                            </span>
                            </p>
                            </div>
                                <div *ngIf="isStep3" style="text-align: center;">
                                <img (click)="openImage('../../../assets/updated_shareDoc_3.png')" *ngIf="isStep3" style="border: 1px solid white;cursor: pointer;" src="../../../assets/updated_shareDoc_3.png" width="400">
                                </div>
                            </div>
            </div>
                    <!-- Share doc ended-->
        <!-- Edit doc started-->
            <div class="openHelpScreens" *ngIf="isOpenEdit">
                <div style="display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 10px;">
                <h1 style="font-family: 'Acumin Variable Concept';margin: 0;">STEPS FOR EDIT DOCUMENTS
                    </h1>
               <button
               style="border: none;
           background: #AA004D;
           color: white;
           padding: 8px;
           border-radius: 4px;
           margin-left: 10px;
           outline: none;
           font-family: 'Century Gothic';"
           (click)="expandOrCollapse(isCollapse = !isCollapse, 'notProfile')"
               >
                   {{buttonText}}
                   </button>
    
               </div>
                <div style="padding-bottom: 10px;margin-bottom: 10px;">
                    <p class="steps" (click)="step('step1')" id="Step1"><i>Step.1</i><i *ngIf="!isStep1" class="fa fa-plus"></i><i *ngIf="isStep1" class="fa fa-minus"></i></p>
                    <div *ngIf="isStep1"  style="font-size: 18px;">
                        <p class="step_para"><span><i class="fa fa-angle-double-right"></i></span> 
                            <span>
                        <!--Go to <a style="color: #ffffff;text-decoration: underline;" [routerLink]="'/caregiverhome'">
                        topicujobs.com caregiverhome page</a> and click on edit icon in actions column of table.-->
                        Login to your Caregiver account, next click on the Open Folder Icon on the left side of the page and view your uploaded documents.
                        </span>
                    </p>
                    <p class="step_para"><span><i class="fa fa-angle-double-right"></i></span> 
                        <span>
                            Click the edit button in the Actions column to the right of the document you wish to edit.
                          </span>
            </p>
                    </div>

                    <div *ngIf="isStep1" style="text-align: center;">
                    <img (click)="openImage('../../../assets/updated_editDoc_1.png')" *ngIf="isStep1" style="border: 1px solid white;cursor: pointer;" src="../../../assets/updated_editDoc_1.png" width="400">
                    </div>
                    </div>
                    <div style="padding-bottom: 10px;margin-bottom: 10px;">
                        <p class="steps"  (click)="step('step2')" id="Step2"><i>Step.2</i><i *ngIf="!isStep2" class="fa fa-plus"></i><i *ngIf="isStep2" class="fa fa-minus"></i></p>
                        <div *ngIf="isStep2" style="font-size: 18px;">
                            <p class="step_para"><span><i class="fa fa-angle-double-right"></i></span> 
                                <span>
                                    A pop-up will appear. You can replace the uploaded file entirely or just edit details such as document name, description, and dates     </span>
                    </p>
                    <p class="step_para"><span><i class="fa fa-angle-double-right"></i></span> 
                        <span>
                            Next click on the Update button to save any edits.
                            </span>
            </p>
                        </div>
                                           <div *ngIf="isStep2" style="text-align: center;">
                        <img (click)="openImage('../../../assets/updated_editDoc_2.png')" *ngIf="isStep2" style="border: 1px solid white;cursor: pointer;" src="../../../assets/updated_editDoc_2.png" width="400">
                    </div>
                        </div>
            </div>
                    <!-- upload doc ended-->
                     <!-- Approve/Reject doc started-->
            <div class="openHelpScreens" *ngIf="isOpenApp_Rej">
                <div style="display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 10px;">
                <h1 style="font-family: 'Acumin Variable Concept';margin: 0;">STEPS FOR APPROVE/REJECT DOCUMENTS
                    </h1>
               <button
               style="border: none;
           background: #AA004D;
           color: white;
           padding: 8px;
           border-radius: 4px;
           margin-left: 10px;
           outline: none;
           font-family: 'Century Gothic';"
           (click)="expandOrCollapse(isCollapse = !isCollapse, 'notProfile')"
               >
                   {{buttonText}}
                   </button>
    
               </div>
                    <div style="padding-bottom: 10px;margin-bottom: 10px;">
                        <p class="steps" (click)="step('step1')" id="Step1"><i>Step.1</i><i *ngIf="!isStep1" class="fa fa-plus"></i><i *ngIf="isStep1" class="fa fa-minus"></i></p>
                        <div *ngIf="isStep1"  style="font-size: 18px;">
                            <p class="step_para"><span><i class="fa fa-angle-double-right"></i></span> 
                                <span>
                            <!--Go to <a style="color: #ffffff;text-decoration: underline;" [routerLink]="'/approve-documents'">
                            topicujobs.com approve documents page</a> and check there is any documents to approve or reject.Go to next step if there are any documents.-->
                            Login to your Caregiver Account and click on the Approve/Reject Documents icon on the left side of the screen. Any documents needing attention will be listed.
                            </span>
                        </p>
                        </div>
    
                        <div *ngIf="isStep1" style="text-align: center;">
                        <img (click)="openImage('../../../assets/updated_apprejDoc_2.png')" *ngIf="isStep1" style="border: 1px solid white;cursor: pointer;" src="../../../assets/updated_apprejDoc_2.png" width="400">
                        </div>
                        </div>
                        <!--<div style="padding-bottom: 10px;margin-bottom: 10px;">
                            <p class="steps"  (click)="step('step2')" id="Step2"><i>Step.2</i><i *ngIf="!isStep2" class="fa fa-plus"></i><i *ngIf="isStep2" class="fa fa-minus"></i></p>
                            <div *ngIf="isStep2" style="font-size: 18px;">
                                <p class="step_para"><span><i class="fa fa-angle-double-right"></i></span> 
                                    <span>
                                        Select the document and click Approve to allow the recruiter to download your document. Select Reject if you do not want to grant the recruiter access to your document.                                </span>
                                </p>
                            </div>
                                               <div *ngIf="isStep2" style="text-align: center;">
                            <img (click)="openImage('../../../assets/updated_apprejDoc_2.png')" *ngIf="isStep2" style="border: 1px solid white;cursor: pointer;" src="../../../assets/updated_apprejDoc_2.png" width="400">
                        </div>
                            </div>-->
                </div>
                        <!-- Approve/Reject doc ended-->
                         <!-- Access doc started-->
            <div class="openHelpScreens" *ngIf="isOpenAcc">
                <div style="display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 10px;">
                <h1 style="font-family: 'Acumin Variable Concept';margin: 0;">STEPS FOR ACCESS DOCUMENTS
                    </h1>
               <button
               style="border: none;
           background: #AA004D;
           color: white;
           padding: 8px;
           border-radius: 4px;
           margin-left: 10px;
           outline: none;
           font-family: 'Century Gothic';"
           (click)="expandOrCollapse(isCollapse = !isCollapse, 'notProfile')"
               >
                   {{buttonText}}
                   </button>
    
               </div>
                    <div style="padding-bottom: 10px;margin-bottom: 10px;">
                        <p class="steps" (click)="step('step1')" id="Step1"><i>Step.1</i><i *ngIf="!isStep1" class="fa fa-plus"></i><i *ngIf="isStep1" class="fa fa-minus"></i></p>
                        <div *ngIf="isStep1"  style="font-size: 18px;">
                            <p class="step_para"><span><i class="fa fa-angle-double-right"></i></span> 
                                <span>
                            <!--Go to <a style="color: #ffffff;text-decoration: underline;" [routerLink]="'/recruiteraccess-documents'">
                            topicujobs.com access documents page</a> and check there is any documents to download.-->
                            Login to your Recruiter Account and select the Access Documents page for a list of available documents.
                            </span>
                        </p>
                        <p class="step_para"><span><i class="fa fa-angle-double-right"></i></span> 
                            <span>
                                Click on the Download icon in the actions column or you can use "Download Documents" button for download multiple documents at a time                       </span>
                             </p>
                        </div>
    
                    <div *ngIf="isStep1" style="text-align: center;">
                        <img (click)="openImage('../../../assets/updated_accDoc_1.png')" *ngIf="isStep1" style="border: 1px solid white;cursor: pointer;" src="../../../assets/updated_accDoc_1.png" width="400">
                    </div>
                        </div>
                        
                </div>
                        <!-- Access doc ended-->
                                  <!-- Request doc started-->
            <div class="openHelpScreens" *ngIf="isOpenReq">
                <div style="display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 10px;">
                <h1 style="font-family: 'Acumin Variable Concept';margin: 0;">STEPS FOR REQUEST DOCUMENTS
                    </h1>
               <button
               style="border: none;
           background: #AA004D;
           color: white;
           padding: 8px;
           border-radius: 4px;
           margin-left: 10px;
           outline: none;
           font-family: 'Century Gothic';"
           (click)="expandOrCollapse(isCollapse = !isCollapse, 'notProfile')"
               >
                   {{buttonText}}
                   </button>
    
               </div>
                    <div style="padding-bottom: 10px;margin-bottom: 10px;">
                        <p class="steps" (click)="step('step1')" id="Step1"><i>Step.1</i><i *ngIf="!isStep1" class="fa fa-plus"></i><i *ngIf="isStep1" class="fa fa-minus"></i></p>
                        <div *ngIf="isStep1"  style="font-size: 18px;">
                            <p class="step_para"><span><i class="fa fa-angle-double-right"></i></span> 
                                <span>
                            <!--Go to <a style="color: #ffffff;text-decoration: underline;" [routerLink]="'/request-documents'">
                            topicujobs.com request documents page</a> and search caregiver with email/ phonenumber to send request.-->
                            Login to your Account and click the Request Documents icon on the left side of the screen and search for the caregiver by email or phone number to send a document request.
                        </span>
                        </p>
                        </div>
    
                        <div *ngIf="isStep1" style="text-align: center;">
                        <img (click)="openImage('../../../assets/updated_reqDoc_1.png')" *ngIf="isStep1" style="border: 1px solid white;cursor: pointer;" src="../../../assets/updated_reqDoc_1.png" width="400">
                        </div>
                        </div>
                        <div style="padding-bottom: 10px;margin-bottom: 10px;">
                            <p class="steps"  (click)="step('step2')" id="Step2"><i>Step.2</i><i *ngIf="!isStep2" class="fa fa-plus"></i><i *ngIf="isStep2" class="fa fa-minus"></i></p>
                            <div *ngIf="isStep2" style="font-size: 18px;">
                                <p class="step_para"><span><i class="fa fa-angle-double-right"></i></span> 
                                    <span>
                                        Review the list of available documents, and select the ones you are requesting access to. Click Request Documents to complete the request.                                     </span>
                                     </p>
                                     <p class="step_para"><span><i class="fa fa-angle-double-right"></i></span> 
                                        <span>
                                            Once the request has been approved by the caregiver, you can download the document from the Access Documents icon on your account homepage.
                                            </span>
                                         </p>
                                     </div>
                                               <div *ngIf="isStep2" style="text-align: center;">
                            <img (click)="openImage('../../../assets/updated_reqDoc_2.png')" *ngIf="isStep2" style="border: 1px solid white;cursor: pointer;" src="../../../assets/updated_reqDoc_2.png" width="400">
                        </div>
                            </div>
                </div>
                        <!-- Request doc ended-->
                          <!-- Profile settings started-->
            <div  class="openHelpScreens" *ngIf="isOpenProfile">
                <div *ngIf="(loginStatus$ | async) as loginstatus">
                    
                <h1 style="font-family: 'Acumin Variable Concept';">STEPS FOR  PROFILE SETTINGS
                    </h1>
                    <div style="display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 10px;">
                    <h2 style="font-family: 'Acumin Variable Concept';margin: 0;">Edit Profile Details
                        </h2>
                   <button
                   style="border: none;
               background: #AA004D;
               color: white;
               padding: 8px;
               border-radius: 4px;
               margin-left: 10px;
               outline: none;
               font-family: 'Century Gothic';"
               (click)="expandOrCollapse(isCollapse = !isCollapse, 'profileEdit')"
                   >
                       {{buttonText}}
                       </button>
        
                   </div>
                    <!--caregiver step 1-->
                    <div *ngIf="caregiverLogin$ | async as caregiverLogin" style="padding-bottom: 10px;margin-bottom: 10px;">
                        <p class="steps" (click)="step('step1')" id="Step1"><i>Step.1</i><i *ngIf="!isStep1" class="fa fa-plus"></i><i *ngIf="isStep1" class="fa fa-minus"></i></p>
                        <div *ngIf="isStep1"  style="font-size: 18px;">
                            <p class="step_para"><span><i class="fa fa-angle-double-right"></i></span> 
                                <span>
                            <!--Go to <a style="color: #ffffff;text-decoration: underline;" [routerLink]="'/profile'">
                            topicujobs.com Profile page</a> for profile edit.-->
                            Click on the Profile icon to see your Personal Profile.
                            </span>
                            </p>
                        </div>
    
                        <div *ngIf="isStep1" style="text-align: center;">
                        <img (click)="openImage('../../../assets/updated_caregiver_1.png')" *ngIf="isStep1" style="border: 1px solid white;cursor: pointer;" src="../../../assets/updated_caregiver_1.png" width="400">
                        </div>
                        </div>
                        <!--recruiter step 1-->
                        <div *ngIf="recruiterLogin$ | async as recruiterLogin" style="padding-bottom: 10px;margin-bottom: 10px;">
                            <p class="steps" (click)="step('step1')" id="Step1"><i>Step.1</i><i *ngIf="!isStep1" class="fa fa-plus"></i><i *ngIf="isStep1" class="fa fa-minus"></i></p>
                            <div *ngIf="isStep1"  style="font-size: 18px;">
                                <p class="step_para"><span><i class="fa fa-angle-double-right"></i></span> 
                                    <span>
                                <!--Go to <a style="color: #ffffff;text-decoration: underline;" [routerLink]="'/profile'">
                                topicujobs.com Profile page</a> for profile edit.-->
                                Click on the Profile icon to see your Personal Profile.
                                </span>
                                </p>
                            </div>
        
                            <div *ngIf="isStep1" style="text-align: center;">
                            <img (click)="openImage('../../../assets/updated_recruiter_1.png')" *ngIf="isStep1" style="border: 1px solid white;cursor: pointer;" src="../../../assets/updated_recruiter_1.png" width="400">
                            </div>
                            </div>
                            <!--caregiver step 2-->
                        <div *ngIf="caregiverLogin$ | async as caregiverLogin" style="padding-bottom: 10px;margin-bottom: 10px;">
                            <p class="steps"  (click)="step('step2')" id="Step2"><i>Step.2</i><i *ngIf="!isStep2" class="fa fa-plus"></i><i *ngIf="isStep2" class="fa fa-minus"></i></p>
                            <div *ngIf="isStep2" style="font-size: 18px;">
                                <p class="step_para"><span><i class="fa fa-angle-double-right"></i></span> 
                                    <span>
                                        Edit any details you want to change and click the Update button to save.
                                    </span></p>
                                    <p class="step_para"><span><i class="fa fa-angle-double-right"></i></span> 
                                        <span>
                                            Updating your email or phone number will require a verification code. Enter the code when prompted and click Update to save.
                                        </span>
                                        </p>
                                        <p class="step_para"><span><i class="fa fa-angle-double-right"></i></span> 
                                            <span> 
                                                Click on the User icon on the left side to upload a profile picture.
                                                </span>
                                            </p>
                                                    </div>
                    <div *ngIf="isStep2" style="text-align: center;">
                            <img (click)="openImage('../../../assets/updated_caregiver_2.png')" *ngIf="isStep2" style="border: 1px solid white;cursor: pointer;" src="../../../assets/updated_caregiver_2.png" width="400">
                        </div>
                            </div>
                                   <!--recruiter step 2-->
                        <div *ngIf="recruiterLogin$ | async as recruiterLogin" style="padding-bottom: 10px;margin-bottom: 10px;">
                            <p class="steps"  (click)="step('step2')" id="Step2"><i>Step.2</i><i *ngIf="!isStep2" class="fa fa-plus"></i><i *ngIf="isStep2" class="fa fa-minus"></i></p>
                            <div *ngIf="isStep2" style="font-size: 18px;">
                                <p class="step_para"><span><i class="fa fa-angle-double-right"></i></span> 
                                    <span>
                                        Edit any details you want to change and click the Update button to save.
                                    </span></p>
                                    <p class="step_para" ><span><i class="fa fa-angle-double-right"></i></span> 
                                        <span>
                                            Updating your email or phone number will require a verification code. Enter the code when prompted and click Update to save.
                                        </span>
                                        </p>
                                        <p class="step_para"><span><i class="fa fa-angle-double-right"></i></span> 
                                            <span> 
                                                Click on the User icon on the left side to upload a profile picture
                                            </span>
                                            </p>
                                                   </div>
                    <div *ngIf="isStep2" style="text-align: center;">
                            <img (click)="openImage('../../../assets/updated_recruiter_2.png')" *ngIf="isStep2" style="border: 1px solid white;cursor: pointer;" src="../../../assets/updated_recruiter_2.png" width="400">
                        </div>
                            </div>
                            </div>
                            <div *ngIf="(loginStatus$ | async) as loginstatus">
                            <!--caregiver/recruiter change/reset password-->
                            <div style="display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 10px;">
                    <h2 style="font-family: 'Acumin Variable Concept';margin: 0;">Change/Reset Password
                        </h2>
                   <button
                   style="border: none;
               background: #AA004D;
               color: white;
               padding: 8px;
               border-radius: 4px;
               margin-left: 10px;
               outline: none;
               font-family: 'Century Gothic';"
               (click)="expandOrCollapse(isResetCollapse = !isResetCollapse, 'pswReset')"
                   >
                       {{resetButtonText}}
                       </button>
        
                   </div>
                            <div style="padding-bottom: 10px;margin-bottom: 10px;">
                                <p class="steps"  (click)="step('step3')" id="Step3"><i>Step.1</i><i *ngIf="!isStep3" class="fa fa-plus"></i><i *ngIf="isStep3" class="fa fa-minus"></i></p>
                                <div *ngIf="isStep3" style="font-size: 18px;">
                                    <p class="step_para"><span><i class="fa fa-angle-double-right"></i></span> 
                                        <span>
                                   <!--Go to <a style="color: #ffffff;text-decoration: underline;" [routerLink]="'/profile'">
                                        topicujobs.com Profile page</a> and click on change password button in the left side.If you want to change password you should need verification code.-->
                                        Click on the Profile icon in navigation and click on the Change Password button in the left column.
                                        </span></p>
                                        <p class="step_para"><span><i class="fa fa-angle-double-right"></i></span> 
                                            <span>
                                                Click “Click Here” link to have a verification code sent to you.
                                                                                    </span>
                                        </p>
                                       </div>
                                                   <div *ngIf="isStep3" style="text-align: center;">
                                                    <div *ngIf="recruiterLogin$ | async as recruiterLogin">
                                <img *ngIf="isStep3" (click)="openImage('../../../assets/updated_recPass_1.png')" style="border: 1px solid white;cursor: pointer;" src="../../../assets/updated_recPass_1.png" width="400">
                                </div>
                                <div *ngIf="caregiverLogin$ | async as caregiverLogin">
                                <img *ngIf="isStep3" (click)="openImage('../../../assets/updated_carePass_1.png')" style="border: 1px solid white;cursor: pointer;" src="../../../assets/updated_carePass_1.png" width="400">
                                </div>
                            </div>
                                </div>
                                <div style="padding-bottom: 10px;margin-bottom: 10px;">
                                    <p class="steps" (click)="step('step4')" id="Step4"><i>Step.2</i><i *ngIf="!isStep4" class="fa fa-plus"></i><i *ngIf="isStep4" class="fa fa-minus"></i></p>
                                    <div *ngIf="isStep4" style="font-size: 18px;">
                                       <p class="step_para" ><span><i class="fa fa-angle-double-right"></i></span> 
                                        <span>
                                            Enter the verification code and your new password, then select Update to save.
                                        </span></p>
                                        <p style="display: flex;"><span style="font-size: 25px;
                                            padding-right: 5px;
                                            color: #f8f8f8;"><i class="fa fa-angle-double-right"></i></span>
                                             <span>
                                                Please check both email and phone for the verification code. If you did not receive it, click Resend.
                                                </span></p>
                                        <p class="step_para"><span><i class="fa fa-angle-double-right"></i></span> 
                                            <span>
                                                Once your password has been changed you will need to login with your new credentials.
                                            </span></p>
                                    </div>
                                        <div *ngIf="isStep4" style="text-align: center;">
                                            <div *ngIf="recruiterLogin$ | async as recruiterLogin">
                                                <img *ngIf="isStep4" (click)="openImage('../../../assets/updated_recPass_2.png')" style="border: 1px solid white;cursor: pointer;" src="../../../assets/updated_recPass_2.png" width="400">
                                                </div>
                                                <div *ngIf="caregiverLogin$ | async as caregiverLogin">
                                                <img *ngIf="isStep4" (click)="openImage('../../../assets/updated_carePass_2.png')" style="border: 1px solid white;cursor: pointer;" src="../../../assets/updated_carePass_2.png" width="400">
                                                </div>
                                        </div>
                                    </div>
                                </div>
                                    <!--caregiver/recruiter forgot password-->
                                    <div style="display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 10px;">
                    <h2 style="font-family: 'Acumin Variable Concept';margin: 0;">Forgot password
                        </h2>
                   <button
                   style="border: none;
               background: #AA004D;
               color: white;
               padding: 8px;
               border-radius: 4px;
               margin-left: 10px;
               outline: none;
               font-family: 'Century Gothic';"
               (click)="expandOrCollapse(isforgotCollapse = !isforgotCollapse, 'forgotPsw')"
                   >
                       {{forgotButtonText}}
                       </button>
        
                   </div>
                   <div style="padding-bottom: 10px;margin-bottom: 10px;">
                                        <p class="steps"  (click)="step('step5')" id="Step5"><i>Step.1</i><i *ngIf="!isStep5" class="fa fa-plus"></i><i *ngIf="isStep5" class="fa fa-minus"></i></p>
                                        <div *ngIf="isStep5" style="font-size: 18px;">
                                            <p class="step_para"><span><i class="fa fa-angle-double-right"></i></span> 
                                                <span>
                                            <!--Go to <a style="color: #ffffff;text-decoration: underline;" [routerLink]="'/login'">
                                                topicujobs.com login page</a> and click on 'Forgot Password' link in login page.-->
                                            	Click on Forgot Password from the login page and enter the email associated with your account to receive a verification code.
                                                </span></p>
                                               </div>
                                                           <div *ngIf="isStep5" style="text-align: center;">
                                        <img (click)="openImage('../../../assets/forgot_1.png')" *ngIf="isStep5" style="border: 1px solid white;cursor: pointer;" src="../../../assets/forgot_1.png" width="400">
                                    </div>
                                        </div>
                                        <div style="padding-bottom: 10px;margin-bottom: 10px;">
                                            <p class="steps"  (click)="step('step6')" id="Step6"><i>Step.2</i><i *ngIf="!isStep6" class="fa fa-plus"></i><i *ngIf="isStep6" class="fa fa-minus"></i></p>
                                            <div *ngIf="isStep6" style="font-size: 18px;">
                                                <p class="step_para"><span><i class="fa fa-angle-double-right"></i></span> 
                                                    <span>
                                                        Enter verification code and click on Validate Account.
                                                        </span>
                                                        </p>
                                                        <p class="step_para"><span><i class="fa fa-angle-double-right"></i></span> 
                                                            <span>
                                                                Please check both phone and email for the code. If you do not see it after 2 minutes please click the link below the button to have a new code sent to you.
                                                    </span></p>
                                                   </div>
                                                               <div *ngIf="isStep6" style="text-align: center;">
                                            <img (click)="openImage('../../../assets/forgot_2.png')" *ngIf="isStep6" style="border: 1px solid white;cursor: pointer;" src="../../../assets/forgot_2.png" width="400">
                                        </div>
                                            </div>
                                            <div style="padding-bottom: 10px;margin-bottom: 10px;">
                                                <p class="steps"  (click)="step('step7')" id="Step7"><i>Step.3</i><i *ngIf="!isStep7" class="fa fa-plus"></i><i *ngIf="isStep7" class="fa fa-minus"></i></p>
                                                <div *ngIf="isStep7" style="font-size: 18px;">
                                                    <p class="step_para"><span><i class="fa fa-angle-double-right"></i></span> 
                                                        <span>
                                                            Enter your new password and click on Reset.
                                                            </span>
                                                            </p>
                                                       </div>
                                                                   <div *ngIf="isStep7" style="text-align: center;">
                                                <img (click)="openImage('../../../assets/forgot_3.png')" *ngIf="isStep7" style="border: 1px solid white;cursor: pointer;" src="../../../assets/forgot_3.png" width="400">
                                            </div>
                                                </div>
                </div>
                        <!-- Profile settings ended-->
            </div>
            <ng-template #img>
                <p style="text-align: right;cursor: pointer;font-size: 30px;
                color: #AA004D;" mat-dialog-close>&times;</p>
                <div>
                <img id="helpImage">
                </div>
                <button class="btn btn-primary" mat-dialog-close>Close</button>
                </ng-template>