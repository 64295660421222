import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SenddocumentComponent } from './senddocument/senddocument.component';
import {UploadDocumentsComponent } from './upload-documents/upload-documents.component';
import {CaregiverhomeComponent} from './caregiverhome/caregiverhome.component'
import { RequestDocumentsComponent } from './request-documents/request-documents.component';
import { RecruiteraccessDocuments } from './recruiteracess-documents/recruiteraccess-documents.component';
import { ApproveDocumentsComponent } from './approve-documents/approvedocuments.component';
import { AuthGuard } from '../authguard.guard';
import { CaregiverAuthGuard } from '../caregiverauth.guard';
import { RecruiterAuthGuard } from '../recruiterauth.guard';






const routes: Routes = [
    {
      path:'approve-documents', component:ApproveDocumentsComponent, canActivate: [CaregiverAuthGuard]
    },
    {
        path: 'caregiverhome', component: CaregiverhomeComponent, canActivate: [CaregiverAuthGuard]
      },
      {
        path:'request-documents', component:RequestDocumentsComponent, canActivate: [RecruiterAuthGuard]
      },
      {
        path:'recruiteraccess-documents', component:RecruiteraccessDocuments, canActivate: [RecruiterAuthGuard]
      }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class DocumentsRoutingModule {}