import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { async } from 'rxjs/internal/scheduler/async';
import { UserService } from './shared-services/userservice.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'CMS-updatedUI';

constructor(public userService:UserService,
  private route: ActivatedRoute, private router: Router
 ){

}
ngOnInit(){
  this.route.queryParams.subscribe(params => {
    if (params['redirect']) {
      this.router.navigateByUrl(params['redirect']);
    }
  });
}
  closeuserProfile(){
    if(localStorage.getItem('Authority') === "CARE_GIVER" || localStorage.getItem('Authority') === "RECRUITER" || localStorage.getItem('Authority') === "ADMIN"){
      this.userService.closeUserDetailsEvent();
    }
  }
}
