import { Component, OnInit } from "@angular/core";

@Component({
    selector: 'app-unauthorized',
    templateUrl: './unauthorized.html',
    styleUrls: ['./unauthorized.scss']
  })
  export class UnauthorizedComponent implements OnInit {
    
    ngOnInit(): void {
    }
  }