import { FormControl, FormGroup, FormGroupDirective, NgForm, ValidatorFn } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
  // custom validator to check that two fields match
  export class CustomValidators{
      static checkPassword: ValidatorFn = (formGroup: FormGroup) =>{
          const [password, confirmPassword] = Object.keys(formGroup.controls || {});
         // var createPassword = formGroup.get(password).value;
          var createPassword = formGroup.get(password).value;
          var confirmpassword =formGroup.get(confirmPassword).value;
          let enteredPassword = formGroup.get(confirmPassword).value
          let passwordCheck = /^(?=.*[a-z])(?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
          if(createPassword !== '' && confirmpassword !== '' && passwordCheck.test(enteredPassword)){
            const isValid = (createPassword === confirmpassword);
            return isValid ? null : {notValid:true};
          }
         
      }
  }
  export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {    
   
if(control.parent.touched && !control.parent.pristine && control.parent.invalid) {
  document.getElementById('passwordFormat').classList.add('passwordFormats');
}else {
  document.getElementById('passwordFormat').classList.remove('passwordFormats');
}
      const invalidCtrl = !!(control && control.invalid && control.touched);
      const invalidParent = !!(control && control.parent.touched && control.parent.invalid && control.parent.dirty && control.parent.hasError('notValid'));
      return ((invalidCtrl || invalidParent));
    }
  }
export const errorMessages: {[key:string]: string} = {
    Password:"Password is required.",
    cnfmPassword:"Confirm Password is required.",
    PasswordFormat:"Password needs to be at least eight characters, one uppercase letter, one special character and one number.",
    ConfirmPassword:"Password should be match."
}
