import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { NavigationComponent } from './navigation/navigation.component';
//import { AppRoutingModule } from '../app-routing.module';
import {MainComponent } from '../layout/main/main.component';
import { HomeComponent } from './home/home.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LayoutRoutingModule } from '../layout/layout-routing.module';
import { from } from 'rxjs';
import { RouterModule } from '@angular/router';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MaterialModule } from '../material.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CaregiverComponent } from './for_caregiver/caregiver.component';
import { RecruiterComponent } from './for_recruiter/recruiter.component';
import { SecurityComponent } from './for_security/security.component';
import { FaqComponent } from './faq/faq.component';


@NgModule({
  declarations: [NavigationComponent, MainComponent, HomeComponent, CaregiverComponent, RecruiterComponent, SecurityComponent, FaqComponent],
  imports: [
    CommonModule,
  // AppRoutingModule,
   LayoutRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatTableModule,
    MatDialogModule,
    MatInputModule,
    MaterialModule,
    MatFormFieldModule,
  ],
  exports:[NavigationComponent,HomeComponent,MainComponent, CaregiverComponent, RecruiterComponent, SecurityComponent, FaqComponent]
})
export class LayoutModule { }
