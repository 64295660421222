import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import * as fileSaver from 'file-saver';
import { AgGridAngular, ICellRendererAngularComp } from "ag-grid-angular";
import { ShareDocumentsService } from "src/app/shared-services/share-documents.service";
import { map, mergeMap } from 'rxjs/operators';
import { UserService } from "src/app/shared-services/userservice.service";
@Component({
    selector: 'download-component',
    template:`
     <a>
  <!--<i *ngIf="isdownloadStarted" class="fa  fa-pulse" style="color:#1b8beb;font-size:18px;margin-left:10px;cursor:pointer"></i>-->
     <i id="download{{params.data.caregiverDocId}}" (click)="downloadDocs()" class="fa fa-arrow-circle-o-down" style="color:#1b8beb;font-size:18px;margin-left:10px;cursor:pointer" title="Download"></i></a>`
})
export class DownloadComponent implements OnInit {
    public params: any;
    public gridApi;
    public gridColumnApi;
    public isdownloadStarted;
    public sentData:any=[];
    public sentData1:any=[];
    public indexNumber=0;
    agInit(params: any): void {
        this.params=params;
        setTimeout(function () {
          if(sessionStorage.getObj('sentData')){
          this.sentData1 = sessionStorage.getObj('sentData');
        /*  for(let a=0;a<params.api.paginationProxy.totalPages;a++){
            if(params.api.paginationProxy.currentPage == a && this.sentData1 > params.api.paginationProxy.pageSize){
              this.indexNumber =params.api.paginationProxy.pageSize*params.api.paginationProxy.currentPage;
            }
            this.indexNumber =0;
          }*/
        if(this.sentData1.length !=0) {
          for( let i =0; i<this.sentData1.length;i++){
            if(this.sentData1[i] === params.data.caregiverDocId){
              var element = document.getElementById('download'+params.data.caregiverDocId);
              element.classList.replace("fa-arrow-circle-o-down", "fa-check-circle");
              element.attributes[0].nodeValue = "Downloaded";
          }
        }
        }
      }
      },10)
      }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
   
  }
  ngOnInit(){
    Storage.prototype.setObj = function(key, obj) {
			return this.setItem(key, JSON.stringify(obj))
		}
		Storage.prototype.getObj = function(key) {
			return JSON.parse(this.getItem(key))
		}

    this.userService.downloadDocs.subscribe((data)=>{
      this.sentData = data;
        if((data.selectedId === this.params.data.caregiverDocId) && !data.isDone && data.selectedIds.length !=0){
        sessionStorage.setObj('sentData', data.selectedIds);
        sessionStorage.setItem('sentData1', data.isDone);
        var element = document.getElementById('download'+this.params.data.caregiverDocId);
      element.classList.replace("fa-arrow-circle-o-down", "fa-pulse");
      element.attributes[0].nodeValue = "Downloading";
      element.classList.add("fa-spinner");
        
      }
      else if((data.selectedId === this.params.data.caregiverDocId) && data.isDone && data.selectedIds.length !=0){
        sessionStorage.setObj('sentData', data.selectedIds);
        sessionStorage.setItem('sentData1', data.isDone);
        var element = document.getElementById('download'+this.params.data.caregiverDocId);
        element.classList.replace("fa-pulse", "fa-check-circle");
        element.classList.remove("fa-spinner");
        element.attributes[0].nodeValue = "Downloaded";

        }
      
    })
  //  console.log(this.sentData);
  }
  constructor(
    public sharedocumentsService:ShareDocumentsService,
    public userService:UserService
  ){

  }
viewbtn(){
    console.log('view btn')
}

  downloadDocs() {
    var element = document.getElementById('download'+this.params.data.caregiverDocId);
    element.classList.replace("fa-arrow-circle-o-down", "fa-pulse");
    element.attributes[0].nodeValue = "Downloading";
    element.classList.add("fa-spinner");
    this.sharedocumentsService.downloadDocument(this.params.data.caregiverDocId, "download").
      subscribe(response => {
        let resp: any = response;
        var element = document.getElementById('download'+this.params.data.caregiverDocId);
        element.classList.replace("fa-pulse", "fa-check-circle");
        element.classList.remove("fa-spinner");
        element.attributes[0].nodeValue = "Downloaded";
        if (!resp.body || resp.body == null || resp.body == 'null') {
          alert('Permission got expired or no file available!');
          return;
        }
        let filename = '';
        let contentType = resp.headers.get('content-type');
        var disposition = resp.headers.get('Content-Disposition');
        if (disposition && disposition.indexOf('attachment') !== -1) {
          var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          var matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1])
            filename = matches[1].replace(/['"]/g, '');
        }
        
        let blob: any = new Blob([resp.body], { type: contentType });
        const url = window.URL.createObjectURL(blob);
        //window.open(url);
        //window.location.href = response.url;
        fileSaver.saveAs(blob, filename.slice(11));
      }),
      error => console.log('Error downloading the file'),
                 () => console.info('File downloaded successfully');
  }

}