<div class="container-fluid" style="width:95%;" *ngIf="!text">
  <!--<div>
    <ul class="navMenu">
      <li class="nav-item"><a class="nav-link"  (click)="sendDocs()">SEND DOCUMENTS</a>
        </li>
        <li  class="nav-item"><a class="nav-link" style="position:relative" (click)="approveDocs()">APPROVE DOCUMENTS
          <span style="position:absolute;top:0;background-color:white;
          color: #2196f3;
          border-radius: 50%;
          padding: 2px 5px;
          margin-top: 1px;" *ngIf="isAlerts">{{alert}}</span>
        </a>
          </li>
        
    </ul>
  </div>-->
 <div *ngIf="senddocuments" class="sub-container">
   <div>
  <form [formGroup]="formGroup" (ngsubmit)="onSubmit(formGroup.value)">
    <div style="width:50%;margin-left:10px;display:flex">
 <mat-form-field  appearance="outline"> 
    <mat-label style="font-size: 17px;"> Email </mat-label>
    <input matInput placeholder="Enter Agency Associate Email" formControlName="email" autocomplete="off">
    <mat-error *ngIf="formGroup.controls['email'].touched">
      {{ getErrorEmail() }}
    </mat-error>
  </mat-form-field>
  <div style="padding-top:8px;margin-left:10px">
    <button *ngIf="!isloading" (click)="searchRecruiter()" style="margin: auto;
    outline: none;
    background-color: #5bc0de;
    border-color: transparent;
    color: white;
    padding: 10px 12px;
    border-radius: 3px;
    font-size: 18px;
" type="submit" [disabled]="!formGroup.valid">search</button>
    <button *ngIf="isloading" style="width:80px;margin:auto;outline: none;
    background-color: #5bc0de;
    border-color: transparent;
    color: white;
    padding: 10px 12px;
    border-radius: 3px;
    font-size: 18px;" ><i  class="fa fa-spinner" style="text-align: center;" aria-hidden="true"></i></button>

  </div>
  </div>
  
  </form>
  <div class="recruiterdetails" style="margin-left: 15px;" *ngIf="recruiterDetails">
    <dl>
      <dt>
        <span>Recruiter AgencyName </span>
        </dt>
        <dd>
          <span>{{recruiterAgencyName}}</span>
          </dd>
    </dl>
    <dl>
              <dt>
                <span>Recruiter FirstName </span>
                </dt>
                <dd>
                  <span>{{recruiterFirstName}}</span>
                  </dd>
    </dl>
    <dl>
                  <dt>
                    <span>Recruiter LastName </span>
                    </dt>
                    <dd>
                      <span>{{recruiterLastName}}</span>
                      </dd>
      </dl>
    <p><a (click)="openAgain()" style="cursor: pointer;" *ngIf="group">Open list again</a></p>
    </div>
    <ng-template #details>
      <p class="crossSymbol" (click)="Close()">&times;</p>
      <div *ngFor= "let searchRecruiter of searchRecruiterDetails" style="padding:10px">
        <dl>
          <dt>
            <span>Recruiter AgencyName </span>
            </dt>
            <dd>
              <span>{{searchRecruiter.agencyName}}</span>
              </dd>
        </dl>
        <dl>
              <dt>
                <span>Recruiter Email </span>
                </dt>
                <dd>
                  <span>{{searchRecruiter.email}}</span>
                  </dd>
        </dl>
        <dl>
                  <dt>
                    <span>Recruiter FirstName </span>
                    </dt>
                    <dd>
                      <span>{{searchRecruiter.firstname}}</span>
                      </dd>
        </dl>
        <dl>
                      <dt>
                        <span>Recruiter LastName </span>
                        </dt>
                        <dd>
                          <span>{{searchRecruiter.lastname}}</span>
                          </dd>
          </dl>

     
      <button class="selectButton" type="submit" (click)="selectUser(searchRecruiter.userId)">Select</button>
      </div>
    <p style="text-align:right"><button class="closeButton" (click)="Close()">Close</button></p>
    </ng-template>
  </div>
  <p>
    <ag-grid-angular 
    #agGrid
    style="height:50vh;padding:10px;width:inherit" 
    id="myGrid"
    [enableColResize]="true"
    class="ag-theme-alpine"
    [rowData]="rowData" 
    (gridReady)="onGridReady($event)"
    rowSelection="multiple"
    [columnDefs]="columnDefs"
    [defaultColDef] ="defaultColDef"
    [modules]="modules"
    (rowSelected)="onRowSelected($event)"
    (selectionChanged)="onSelectionChanged($event)"
    [rowClassRules]="rowClassRules">
  </ag-grid-angular>
    </p>
    <button class="btn btn-primary"  style="width:auto;float: right;" (click)="btnClickedHandler()" type="submit" [disabled]="!enableButton">SEND DOCUMENT</button>

</div>
</div>
<div *ngIf="text" class="textContainer">
<div class="initialScreen">
 <h1>Caregivers Can Share Documents Securely</h1>
 <p>Caregiver can store his/her documents safely and can send documents to recruiter by searching their email/phoneNumber and have a chance to accept the recruiter request to download or view your documents</p>
 <a class="startbtn" [routerLink]="'/login'" routerLinkActive="active">GET STARTED</a>

  </div>
  </div> 
  