<div class="container-fluid uploadscreen" *ngIf="!text"> 
  <div class="container-small" *ngIf="!displayVideo">
   <div class="fadeIn first loginHeading">
      <span>Upload Document</span>
      </div>
      <div>
        
<div class="buttonContainer">
      <button  *ngIf="!displayVideo" class="cameraIcon" (click)="startCamera()"><i class="fa fa-camera" aria-hidden="true"></i><i class="text">TAKE A PICTURE OF <br> DOCUMENT</i></button>
      <button #uploadfile *ngIf="!displayVideo" class="uploadIcon" (click)="uploadDocument()"><i class="fa fa-file-text-o" aria-hidden="true"></i><i  class="text1">UPLOAD <br>YOUR <br>DOCUMENT</i></button>
     </div>
     </div>
  </div>
   <div *ngIf="displayVideo">  <video #video id="myVideo" autoplay></video></div>
  
      <div class="d-flex flex-column align-items-center">
        <div>
          <canvas #canvas  id="img"></canvas>
  </div>
      <div class="pb-2">
        <button *ngIf="displayVideo" class="btn btn-primary" (click)="capture()">Capture Document Picture</button>
        <button *ngIf="uploaddocument" style="margin-left:15px" class="btn btn-primary" (click)="upload()">Upload Document</button>
      </div>
    
     </div>
     <ng-template #addDocument>
     <div>
     <form [formGroup]="newDocumentForm" (ngSubmit)="submitNewDocument(newDocumentForm.value)" novalidate>
       
      <div style="width: 600px;"> 
          
        <mat-form-field   appearance="outline"> 
          <mat-label style="font-size: 17px;"> Document Name</mat-label>
          <mat-select matInput  placeholder="Choose documentName" formControlName="documentName" (selectionChange)="populatedocType($event)"  autocomplete="off">
          <mat-option>Choose  DocumentName</mat-option>
          <mat-option value ="{{document.id}}" *ngFor="let document of documentNames" [ngValue]="docId">{{document.documentName}}</mat-option>
          </mat-select>
          <mat-error *ngIf="!newDocumentForm.controls['documentName'].valid && newDocumentForm.controls['documentName'].touched">
           DocumentName is required
          </mat-error>
        </mat-form-field>
     </div>
      <div>    
        <mat-form-field   appearance="outline"> 
          <mat-label style="font-size: 17px;"> Document Type</mat-label>
          <mat-select matInput  placeholder="Choose documentName" formControlName="documentType"  autocomplete="off">
          <mat-option>Choose  documentType</mat-option>
          <mat-option value={{documentTypes}}>{{documentTypes}}</mat-option>
          </mat-select>
          <mat-error *ngIf="!newDocumentForm.controls['documentType'].valid && newDocumentForm.controls['documentType'].touched">
           DocumentType is required
          </mat-error>
        </mat-form-field>
    
     </div>
     <div> <mat-form-field  appearance="outline">
      <mat-label> Description </mat-label>
      <input matInput placeholder="Description" formControlName="description"  autocomplete="off">
      <mat-error *ngIf="!newDocumentForm.controls['description'].valid && newDocumentForm.controls['description'].touched">
        description is required
      </mat-error>
    </mat-form-field>
    
    </div>
    <div formGroupName="dateGroup">
    <div> <mat-form-field appearance="outline">
      <mat-label>Start-Date</mat-label>
      <input matInput type="date" placeholder="Start Date" formControlName="uploadDate">
     
       <mat-error>{{errors.uploaddate}}</mat-error>
    </mat-form-field>  
    </div>
    
    <div><mat-form-field appearance="outline">
    <mat-label>Expiration-Date</mat-label>
    <input matInput type="date" placeholder="End Date" formControlName="expiryDate" [errorStateMatcher]="matcher">
     <mat-error>{{errors.expirydate}}</mat-error>
     <mat-error></mat-error>
    </mat-form-field>
    </div>
    </div>
    <div mat-dialog-actions style="float:right">
      <button class="btn btn-primary" mat-button type="submit" style="color:white"  [disabled]="!newDocumentForm.valid" mat-dialog-close>Add Document</button>
      <button class="btn btn-danger" mat-button style="background-color: #554d4d;" mat-dialog-close>Cancel</button>
      </div>
    </form>
    </div>
    </ng-template>
     </div>
     <div *ngIf="text" class="textContainer">
      <div class="initialScreen">
       <h1>Caregiver Can Upload Documents By Using Camera </h1>
       <p>Caregivers can upload your documents safely by using camera or select file from their device </p>
       <a class="startbtn" [routerLink]="'/login'" routerLinkActive="active">GET STARTED</a>
      
        </div>
        </div> 