// caregiver.guard.ts
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AdminAuthGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(): boolean {
    // Check if the user has the caregiver role here.
   // if(localStorage.getItem('Authority') === "CARE_GIVER" || localStorage.getItem('Authority') === "RECRUITER" || localStorage.getItem('Authority') === "ADMIN"){

    if (localStorage.getItem('Authority') === "ADMIN") {
      return true;
    } else if (localStorage.getItem('Authority') === "CARE_GIVER" || localStorage.getItem('Authority') === "RECRUITER") {
      this.router.navigate(['/unauthorized']); // Redirect to an unauthorized page
      return false;
    }else{
      this.router.navigate(['/login']);
      return false;
    }
  }
}
