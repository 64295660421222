<div  style="background-color: #2777BA;margin-left: auto;width:85%;padding: 20px;" id="faqScreen">
    <div>
        <h1 style="color: white;
        /* padding-left: 80px; */
        text-align: center;
        text-transform: uppercase;
        font-family: 'Acumin Variable Concept';
        margin-bottom: 0;">Frequently asked questions</h1>
        <div *ngIf="isRecBtn && isCareBtn" style="width:85%;margin:10px auto;text-align:center">
            <!--<button style="background-color: transparent;border: none;outline: none;"><img width="130px" height="130px" src="../../../assets/CaregiverIconss-01.png"></button>
            <button style="background-color: transparent;border: none;outline: none;"><img width="80px" height="130px" src="../../../assets/CaregiverIconss-02.png"></button>-->
            <button id="caregiverFaqBtn" class="faqButn" (click)="caregiverFaq()"><img width="150px" height="140px" src="../../../assets/CaregiverIconss-01.png"><p>CAREGIVER</p></button>
            <button id="recruiterFaqBtn" class="faqButn" (click)="recruiterFaq()"><img width="135px" height="140px" src="../../../assets/CaregiverIconss-02.png"><p>RECRUITER</p></button>
            </div>
       <!--<div style="text-align: center;
       width: 85%;
       margin: 10px auto 20px;">
           <input style="outline: none;
           border: none;
           width: 50%;
           padding: 10px;
           border-top-left-radius: 3px;
           border-bottom-left-radius: 3px;" placeholder="Search" type="search">
           <button style="border: none;
           background: #cfcfcf;
           padding: 10px 20px;
           color:#2777BA;
           border-top-right-radius: 2px;
           border-bottom-right-radius: 2px;
           outline: none;"><i style="font-size: 16px;" class="fa fa-search"></i>
               </button>
           </div>-->
        <div style="width: 85%;
        margin: 0 auto;
        background: white;
        padding: 45px 50px 20px;">
         <div style="text-align: right;
          width: 95%; 
         margin-left: auto;
         margin-right: auto;
         margin-bottom: 20px;
         ">
             <button
             style="border: none;
         background: #AA004D;
         color: white;
         padding: 10px;
         border-radius: 4px;
         margin-left: 10px;
         outline: none;
         font-family: 'Century Gothic';"
         (click)="expandOrCollapse(isCollapse = !isCollapse)"
             >
                 {{buttonText}}
                 </button>

             </div>
             <div *ngIf="isCaregiverFaq && !isRecruiterFaq">
            <div style="padding-bottom: 10px;
            margin-bottom: 10px;
            width: 95%;
            margin-left: auto;
            margin-right: auto;">
                <p class="steps" (click)="step('step1')" id="Step1"><i>Who are we?</i><i *ngIf="!isStep1" class="fa fa-plus"></i><i *ngIf="isStep1" class="fa fa-minus"></i></p>
                <div *ngIf="isStep1"  style="font-size: 18px;">
                    <p class="step_para">
                        <span>
                            If you have ever worked for a healthcare agency, you know how tedious it can be trying to get your documents organized before you start your contract. 
                            At GCredentials we simplify that process by creating a secure site to store everything you need, and the ability to share your documents to as many recruiters as you wish. 
                            No more digging through emails trying to find your skills checklist, or calling doctors for copies of your physical. 
                            Simply upload your document, and GCredentials will keep it safe until you are ready to share it with Recruiters.                    <!--Go to <a style="color: #ffffff;text-decoration: underline;" [routerLink]="'/caregiverhome'">topicujobs.com caregiverhome page</a> and click on Upload document.-->
                    </span>
                </p>
                <p class="contactUs"><i class="fa fa-envelope"></i>Still need help?<a href="mailto:Admin@Gcredentials.com">Contact Us</a></p>
                </div>
                </div>
                <div style="padding-bottom: 10px;
                margin-bottom: 10px;
                width: 95%;
                margin-left: auto;
                margin-right: auto;">
                    <p class="steps" (click)="step('step2')" id="Step2"><i>Can I share it with anyone?</i><i *ngIf="!isStep2" class="fa fa-plus"></i><i *ngIf="isStep2" class="fa fa-minus"></i></p>
                    <div *ngIf="isStep2"  style="font-size: 18px;">
                        <p class="step_para"> 
                            <span>
                                Yes! With GCredentials you are in complete control of who gains access to your documents, from Recruiters to Compliance personnel and more, from any Agency. 
                                In addition, you can pick and choose which files you want them to see, whether it's one or all.
                                 So if you are ready to send a recruiter your resume but aren't quite ready to send your driver’s license, that’s okay! You are in charge of who has access to each document you upload, individually.          
                                   </span>
                    </p>
                    <p class="contactUs"><i class="fa fa-envelope"></i>Still need help?<a href="mailto:Admin@Gcredentials.com">Contact Us</a></p>
                    </div>
                    </div>
                    <div style="padding-bottom: 10px;
                    margin-bottom: 10px;
                    width: 95%;
                    margin-left: auto;
                    margin-right: auto;">
                        <p class="steps" (click)="step('step3')" id="Step3"><i>Is my information secure?</i><i *ngIf="!isStep3" class="fa fa-plus"></i><i *ngIf="isStep3" class="fa fa-minus"></i></p>
                        <div *ngIf="isStep3"  style="font-size: 18px;">
                            <p class="step_para"> 
                                <span>
                                    Absolutely. In addition to a multitude of encryptions to protect your private information, we also guarantee we will never sell your information to any third parties. 
                                    Only you control who can see your documents, period.               <!--Go to <a style="color: #ffffff;text-decoration: underline;" [routerLink]="'/caregiverhome'">topicujobs.com caregiverhome page</a> and click on Upload document.-->
                            </span>
                        </p>
                        <p class="contactUs"><i class="fa fa-envelope"></i>Still need help?<a href="mailto:Admin@Gcredentials.com">Contact Us</a></p>
                        </div>
                        </div>
                        <div style="padding-bottom: 10px;
                        margin-bottom: 10px;
                        width: 95%;
                        margin-left: auto;
                        margin-right: auto;">
                            <p class="steps" (click)="step('step4')" id="Step4"><i>How does it work?</i><i *ngIf="!isStep4" class="fa fa-plus"></i><i *ngIf="isStep4" class="fa fa-minus"></i></p>
                            <div *ngIf="isStep4"  style="font-size: 18px;">
                                <p class="step_para">
                                    <span>
                                        Create a profile, upload documents, and you are done! Recruiters can request documents from you and you can grant access to them, or you can send documents directly without waiting. 
                                        Both Recruiters and Caregivers need profiles to exchange documents.                   <!--Go to <a style="color: #ffffff;text-decoration: underline;" [routerLink]="'/caregiverhome'">topicujobs.com caregiverhome page</a> and click on Upload document.-->
                                </span>
                            </p>
                            <p class="contactUs"><i class="fa fa-envelope"></i>Still need help?<a href="mailto:Admin@Gcredentials.com">Contact Us</a></p>
                            </div>
                            </div>
                            <div style="padding-bottom: 10px;
                            margin-bottom: 10px;
                            width: 95%;
                            margin-left: auto;
                            margin-right: auto;">
                                <p class="steps" (click)="step('step5')" id="Step5"><i>How much does it cost?</i><i *ngIf="!isStep5" class="fa fa-plus"></i><i *ngIf="isStep5" class="fa fa-minus"></i></p>
                                <div *ngIf="isStep5"  style="font-size: 18px;">
                                    <p class="step_para">
                                        <span>
                                            Nothing. Zilch. Nada. We do not charge Caregivers or Agencies to use our service. It is free for everyone, always.                   <!--Go to <a style="color: #ffffff;text-decoration: underline;" [routerLink]="'/caregiverhome'">topicujobs.com caregiverhome page</a> and click on Upload document.-->
                                    </span>
                                </p>
                                <p class="contactUs"><i class="fa fa-envelope"></i>Still need help?<a href="mailto:Admin@Gcredentials.com">Contact Us</a></p>
                                </div>
                                </div>
                                <div style="padding-bottom: 10px;
                            margin-bottom: 10px;
                            width: 95%;
                            margin-left: auto;
                            margin-right: auto;">
                                <p class="steps" (click)="step('step6')" id="Step6"><i>How can I create a profile and start uploading documents?</i><i *ngIf="!isStep6" class="fa fa-plus"></i><i *ngIf="isStep6" class="fa fa-minus"></i></p>
                                <div *ngIf="isStep6"  style="font-size: 18px;">
                                    <p class="step_para">
                                        <span>
                                            <a [routerLink]="'/help'">Click here</a> for step by step instructions on how to create your profile, upload your documents, and share them securely                    <!--Go to <a style="color: #ffffff;text-decoration: underline;" [routerLink]="'/caregiverhome'">topicujobs.com caregiverhome page</a> and click on Upload document.-->
                                    </span>
                                </p>
                                <p class="contactUs"><i class="fa fa-envelope"></i>Still need help?<a href="mailto:Admin@Gcredentials.com">Contact Us</a></p>
                                </div>
                                </div>
                                </div>
                            <div *ngIf="!isCaregiverFaq && isRecruiterFaq">
                           <div style="padding-bottom: 10px;
                           margin-bottom: 10px;
                           width: 95%;
                           margin-left: auto;
                           margin-right: auto;">
                               <p class="steps" (click)="step('step1')" id="Step1"><i>Who are we?</i><i *ngIf="!isStep1" class="fa fa-plus"></i><i *ngIf="isStep1" class="fa fa-minus"></i></p>
                               <div *ngIf="isStep1"  style="font-size: 18px;">
                                   <p class="step_para">
                                       <span>
                                        <p>GCredentials is a safe place for caregivers of all specialties to save, organize, and share their documents for easy access when they (and you!) need it most.
                                             Anyone who has worked in healthcare staffing knows how difficult it can be to obtain documents in time for a submission, or start date.</p>

                                        <p>Imagine this- you have the perfect candidate for the perfect job.
                                             They signed the contract for a quick start and ready for their first day… but they can’t find their 2-step ppd from 6 months ago and now the start date has to be pushed so they can get another done, or the facility cancels the contract entirely.
                                              Sound familiar? We’ve all been there.
                                            At GCredentials we want to provide a place to eliminate the hassle, and streamline the compliance process so they can start on time, each time, and even sooner.</p>                  <!--Go to <a style="color: #ffffff;text-decoration: underline;" [routerLink]="'/caregiverhome'">topicujobs.com caregiverhome page</a> and click on Upload document.-->
                                   </span>
                               </p>
                               <p class="contactUs"><i class="fa fa-envelope"></i>Still need help?<a href="mailto:Admin@Gcredentials.com">Contact Us</a></p>
                               </div>
                               </div>
                                       <div style="padding-bottom: 10px;
                                       margin-bottom: 10px;
                                       width: 95%;
                                       margin-left: auto;
                                       margin-right: auto;">
                                           <p class="steps" (click)="step('step2')" id="Step2"><i>How does it work?</i><i *ngIf="!isStep2" class="fa fa-plus"></i><i *ngIf="isStep2" class="fa fa-minus"></i></p>
                                           <div *ngIf="isStep2"  style="font-size: 18px;">
                                               <p class="step_para">
                                                   <span>
                                                    Caregivers upload any documents they choose, and share them one of two ways. You can request access by using their email address, at which point they must grant the access request for documents to be shared. 
                                                    The Caregiver can also choose to send the documents to you directly if they choose, without waiting for a request.                   <!--Go to <a style="color: #ffffff;text-decoration: underline;" [routerLink]="'/caregiverhome'">topicujobs.com caregiverhome page</a> and click on Upload document.-->
                                               </span>
                                           </p>
                                           <p class="contactUs"><i class="fa fa-envelope"></i>Still need help?<a href="mailto:Admin@Gcredentials.com">Contact Us</a></p>
                                           </div>
                                           </div>
                                           <div style="padding-bottom: 10px;
                                           margin-bottom: 10px;
                                           width: 95%;
                                           margin-left: auto;
                                           margin-right: auto;">
                                               <p class="steps" (click)="step('step3')" id="Step3"><i>How much does it cost?</i><i *ngIf="!isStep3" class="fa fa-plus"></i><i *ngIf="isStep3" class="fa fa-minus"></i></p>
                                               <div *ngIf="isStep3"  style="font-size: 18px;">
                                                   <p class="step_para">
                                                       <span>
                                                        Nothing. Zilch. Nada. We do not charge Caregivers or Agencies to use our service. We will not say no to a social distancing- friendly elbow bump though!                   <!--Go to <a style="color: #ffffff;text-decoration: underline;" [routerLink]="'/caregiverhome'">topicujobs.com caregiverhome page</a> and click on Upload document.-->
                                                   </span>
                                               </p>
                                               <p class="contactUs"><i class="fa fa-envelope"></i>Still need help?<a href="mailto:Admin@Gcredentials.com">Contact Us</a></p>
                                               </div>
                                               </div>
                                               <div style="padding-bottom: 10px;
                                           margin-bottom: 10px;
                                           width: 95%;
                                           margin-left: auto;
                                           margin-right: auto;">
                                               <p class="steps" (click)="step('step4')" id="Step4"><i>Do I have a profile to receive documents? </i><i *ngIf="!isStep4" class="fa fa-plus"></i><i *ngIf="isStep4" class="fa fa-minus"></i></p>
                                               <div *ngIf="isStep4"  style="font-size: 18px;">
                                                   <p class="step_para">
                                                       <span>
                                                        Yes, all document sharing is to verified accounts only to protect privacy                    <!--Go to <a style="color: #ffffff;text-decoration: underline;" [routerLink]="'/caregiverhome'">topicujobs.com caregiverhome page</a> and click on Upload document.-->
                                                   </span>
                                               </p>
                                               <p class="contactUs"><i class="fa fa-envelope"></i>Still need help?<a href="mailto:Admin@Gcredentials.com">Contact Us</a></p>
                                               </div>
                                               </div>
                                               </div>
                                            </div>
            </div>
        <div style="background: #ebebeb;
        padding: 20px;
        color: #2777BA;
        text-align: center;
        font-size: 20px;
        width: 85%;
        margin: 0 auto;
        font-family: 'Century Gothic';">
            Do you have more questions?
            <a href="mailto:Admin@Gcredentials.com">
        <button style="border: none;
        background: #2777BA;
        color: white;
        padding: 10px;
        border-radius: 4px;
        margin-left: 10px;outline: none;">Contact us</button>
        </a>
        </div>
</div>


