import { Injectable } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
//import { ValidationErrors } from 'aws-sdk/clients/datapipeline';
import { from } from 'rxjs';
import {Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  phoneregex: RegExp = /^\(?(\d{3})\)?[-\. ]?(\d{3})[-\. ]?(\d{4})$/ 
  otcregex:RegExp = /^[0-9]{4}$/
  passwordregex:RegExp = /^(?=.*[a-z])(?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
  emailorphoneRegexp:RegExp = /^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/;
   //phone validation for  = 123-123-1234 or (123) 123-1234 or 1231231234
   public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
}
  constructor() { }
  
checkPassword(control) {
  let enteredPassword = control.value
  let passwordCheck = /^(?=.*[a-z])(?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
  return (!passwordCheck.test(enteredPassword) && enteredPassword) ? { 'requirements': true } : null;
}
checkPhoneNumber(control) {
  if(control.value !=null){
    let enteredNumber = control.value.trim();
  let phoneCheck = /^\(?(\d{3})\)?[-\. ]?(\d{3})[-\. ]?(\d{4})$/;
  return (!phoneCheck.test(enteredNumber) && enteredNumber) ? { 'phoneRequirements': true } : null;
  }
}
checkEmail(control){
  if(control.value !=null){
let enteredEmail = control.value.trim();
let emailCheck = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
return (!emailCheck.test(enteredEmail) && enteredEmail) ? { 'emailrequire':true } :null;
  }
}
checkPhoneorEmail(control){
  if(control.value !=null){
let enteredPhoneOrEmail = control.value.trim();

let phoneOrEmailCheck = /^(\(?(\d{3})\)?[-\. ]?(\d{3})[-\. ]?(\d{4})|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/;
return (!phoneOrEmailCheck.test(enteredPhoneOrEmail) && enteredPhoneOrEmail) ? { 'emailorphonerequire':true } :null;
  }
}
checkInUseEmail(control) {
  // mimic http database access
  let db = ['jack@torchwood.com'];
  return new Observable(observer => {
    setTimeout(() => {
      let result = (db.indexOf(control.value) !== -1) ? { 'alreadyInUse': true } : null;
      observer.next(result);
      observer.complete();
    }, 4000)
  })
}

}
