import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { JwtModule, JWT_OPTIONS } from './shared-services/angular-jwt/jwt.module';

import { AppRoutingModule } from './app-routing.module';
import {LayoutRoutingModule } from './layout/layout-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from "./layout/layout.module";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthenticationModule } from './authentication/authentication.module';
import { DocumentsModule } from "./documents/documents.module";
import { MaterialModule } from './material.module';
import {MatTableModule} from '@angular/material/table';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import {TOKEN_NAME ,TWO_FACTOR_AUTH_TOKEN} from './shared-services/auth.constant'
import { GOOGLE_LOGIN_CLIENT_ID, FACEBOOK_LOGIN_CLIENT_ID } from './shared-services/auth.constant';
import {  AgGridModule } from 'ag-grid-angular';
import { AdminPageModule } from './adminPage/adminPage.module';



/* import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader'; */

import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import{AuthenticationService} from './shared-services/authentication.service';
import{ UserService } from './shared-services/userservice.service';
import {AdminService} from './shared-services/adminservice.service';
import { ProfessionDataService } from "./shared-services/profession-data.service";
import { AlertService } from './shared-services/alert.service';
import { SocialLoginModule, SocialAuthServiceConfig } from "angularx-social-login";
import { GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login";
import {AppHttpInterceptor} from './AppHttpInterceptor';
import { AlertsModule } from './alerts/alert.module';
import { AdminPageRoutingModule } from './adminPage/adminPage-routing.module';
/* // AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  // for development
   return new TranslateHttpLoader(http, '/start-angular/SB-Admin-BS4-Angular-5/master/dist/assets/i18n/', '.json');
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
} */

/*  let config = new SocialAuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider(GOOGLE_LOGIN_CLIENT_ID)
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider(FACEBOOK_LOGIN_CLIENT_ID)
  }
]); 
export function provideConfig() {
   return config;
 }   */

export function jwtOptionsFactory() {
return {
  tokenGetter: () => {
    return sessionStorage.getItem(TOKEN_NAME);
  },
  token2FAGetter: () => {
    return localStorage.getItem(TWO_FACTOR_AUTH_TOKEN);
  },
  whitelistedDomains: ['localhost:8080']
};
}



@NgModule({
  declarations: [
    AppComponent
    
   
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    LayoutRoutingModule,
    LayoutModule,
    AdminPageRoutingModule,
    AdminPageModule,
    HttpClientModule,
    AuthenticationModule,
    DocumentsModule,
    MaterialModule,
    MatFormFieldModule,
    SocialLoginModule,
    AgGridModule.withComponents([]),
    MatInputModule,
    MatTableModule,
    FormsModule,
    AlertsModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory
      }
    }),
   
  ],
/*   providers: [
    AuthenticationService,
    ProfessionDataService,
    UserService,
    {
      provide: 'SocialAuthServiceConfig',
      useFactory: provideConfig,
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              GOOGLE_LOGIN_CLIENT_ID
            ),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(FACEBOOK_LOGIN_CLIENT_ID),
          },
        ],
      } as SocialAuthServiceConfig,
    }
  ], */
  providers: [
    AuthenticationService,
    ProfessionDataService,
    AdminService,
    UserService,
    AlertService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              GOOGLE_LOGIN_CLIENT_ID
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(FACEBOOK_LOGIN_CLIENT_ID)
          }
        ]
      } as SocialAuthServiceConfig,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppHttpInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
