import { Component, OnInit,ElementRef, Renderer2, ViewChild, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { mergeMap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/shared-services/authentication.service';
import { ShareDocumentsService } from 'src/app/shared-services/share-documents.service';
import { AddDocumentsComponent } from '../add-documents/add-documents.component';
import { DocumentsModule } from '../documents.module';
import {
  CustomValidators,
  errorMessages,
  MyErrorStateMatcher,
} from '../../documents/add-documents/date.match';
import { UserService } from 'src/app/shared-services/userservice.service';
@Component({
  selector: 'app-upload-documents',
  templateUrl: './upload-documents.component.html',
  styleUrls: ['./upload-documents.component.scss']
})
export class UploadDocumentsComponent implements OnInit {
  capaturedImage;
  newDocumentForm: FormGroup;
  displayVideo:boolean;
  uploaddocument:boolean;
  documentForm: FormGroup;
  adddocument:boolean;
  @ViewChild('video') videoElement: ElementRef<any>;
  @ViewChild('canvas') canvas: ElementRef<any>;
  @ViewChild("file") file:ElementRef<any>;
  registerToken: any;
  documentNames: any;
  displayCam:boolean;
  matcher = new MyErrorStateMatcher();
  errors = errorMessages;
  postedValues: any;
  documentTypes;
  docId: number;
  uploadedDocument;
  
  text;
  videoWidth = 0;
  videoHeight = 0;
  constraints = {
        video: {
            facingMode: "environment",
            width: { ideal: 4096 },
            height: { ideal: 2160 }
        }
    };
    @ViewChild("addDocument") addDocument:TemplateRef<any>
  constructor(private renderer: Renderer2, 
    public dialog:MatDialog,
    private router:Router, 
    private route:ActivatedRoute, 
    private authenticationService:AuthenticationService, 
    private shareDocumentsService: ShareDocumentsService ,
    private userService:UserService,
    private formBuilder: FormBuilder)
     {
      if((localStorage.getItem('Authority')!=='CARE_GIVER')){
        this.text = true;
      }
      else {
        this.text = false;
      }
      this.newDocumentForm = new FormGroup({
        documentName: new FormControl('', [Validators.required]),
        documentType: new FormControl('', [Validators.required]),
        description: new FormControl('', [Validators.required]),
        expiryDate: new FormControl('', [Validators.required]),
        uploadDate: new FormControl('', [Validators.required]),
      });
      }

  ngOnInit(): void {
    document.body.style.backgroundImage = "none";
    if((localStorage.getItem('Authority')=='CARE_GIVER')){
      this.getDocumentName();
    }
  
   this.createDocumentForm();
  }
  createDocumentForm() {
    this.newDocumentForm = this.formBuilder.group({
      documentName: [null, [Validators.required]],
      documentType: [null, [Validators.required]],
      description: [null, [Validators.required]],
      dateGroup: this.formBuilder.group(
        {
          expiryDate: [null, [Validators.required]],
          uploadDate: [null, [Validators.required]],
        },
        {
          validator: CustomValidators.checkDates,
        }
      ),
    });
  }

  getDocumentName(){
   
       this.authenticationService.getDocumentNames()
      .subscribe(documentsObj => {
            if (documentsObj) {
              this.documentNames = documentsObj.body;
             
            }
    },
    (err)=>{
     console.log('err', err);
    }
    );
  
  }
  populatedocType(event) {
    let i = event.value;
    for(let a=0; a<this.documentNames.length; a++){
      if(this.documentNames[a].id == i){
        this.documentTypes = this.documentNames[a].documentType;
        this.docId = this.documentNames[a].id;
      }
    }
  }

  submitNewDocument(values) {
    this.postedValues = values;
    this.uploadedDocument.append('docTypeId', this.docId);
    this.uploadedDocument.append('expiryDate', values.dateGroup.expiryDate);
    this.uploadedDocument.append('uploadDate', values.dateGroup.uploadDate);
    this.uploadedDocument.append('description', values.description);

    this.shareDocumentsService
      .capturedImageUpload(this.docId, this.uploadedDocument)
      .subscribe((result) => {
        this.router.navigateByUrl('/profile');
        this.router.navigate(['/profile'], { relativeTo:this.route})
        return result;
      }
      );
  }
  
  startCamera() {
    this.displayCam = true;
    this.displayVideo = true;
    this.renderer.setProperty(this.canvas.nativeElement, 'height', 0);
    this.renderer.setProperty(this.canvas.nativeElement, 'width', 0);
    if ((navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
        navigator.mediaDevices.getUserMedia(this.constraints).then(this.attachVideo.bind(this)).catch(this.handleError);
    } else {
        alert('Sorry, camera not available.');
    }
}

attachVideo(stream) {
    this.renderer.setProperty(this.videoElement.nativeElement, 'srcObject', stream);
    if(this.displayCam){
    this.renderer.listen(this.videoElement.nativeElement, 'play', (event) => {
        this.videoHeight = this.videoElement.nativeElement.videoHeight;
        this.videoWidth = this.videoElement.nativeElement.videoWidth;

    });
  }
 
}
screen(){
 
 
  
}
 stopVideo(){
  const stream = this.videoElement.nativeElement.srcObject;
  const tracks = stream.getTracks();
  this.uploaddocument = true;
  tracks.forEach(function(track) {
    track.stop();
  });

  this.videoElement.nativeElement.srcObject = null;
  this.renderer.setProperty(this.videoElement.nativeElement, 'height', 0);
}
capture() {
 // this.displayVideo = false;
    this.renderer.setProperty(this.canvas.nativeElement, 'width', this.videoWidth);
    this.renderer.setProperty(this.canvas.nativeElement, 'height', this.videoHeight);
    
    this.canvas.nativeElement.getContext('2d').drawImage(this.videoElement.nativeElement, 0, 0);
    this.capaturedImage = this.canvas.nativeElement.toDataURL();
    var file = this.datURItoBlob(this.capaturedImage);
    this.displayVideo = false;
   if(file.size > 2e+7){
     alert('Image size more than 20Mb so please make sure it should be equal or less than 20Mb');
   }
   else{
        let formData: FormData = new FormData();
        formData.append('uploadFile', file);
        this.uploadedDocument = formData;
        console.log(file.size);
   }
  
   
    console.log(file);
   // this.displayCam = false;
    this.stopVideo();
}
datURItoBlob(dataURI){
// convert base64/URLEncoded data component to raw binary data held in a string
var byteString;
if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1]);
else
    byteString = unescape(dataURI.split(',')[1]);
// separate out the mime component
var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
// write the bytes of the string to a typed array
var ia = new Uint8Array(byteString.length);
for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
}
return new Blob([ia], {type:mimeString});
}
upload(){
 this.dialog.open(this.addDocument);
  //this.adddocument = true;
}

uploadDocument() {
  this.dialog.open(AddDocumentsComponent);
}
handleError(error) {
  console.log('Error: ', error);
}

}
