import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import {MatTableModule} from '@angular/material/table';
import { AgGridModule } from 'ag-grid-angular';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatDialogModule} from '@angular/material/dialog';
import { MaterialModule } from '../material.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { from } from 'rxjs';
import { RouterModule } from '@angular/router';
import { AngularFileViewerModule } from '@taldor-ltd/angular-file-viewer';
import { AdminMainPageComponent } from './adminMainPage/adminMainPage.component';
import { UserSessionRepComponent } from './userSessionRep/userSessionRep.component';
import {UserActivityRepComponent} from './userActivityRep/userActivityRep.component';
import { DocumentTrackingComponent } from './documentTracking/documentTracking.component';
import { AdminActionComponent } from './adminMainPage/adminAction.component';
import { EditProfileComponent } from './edit-profiles/edit-profile.component';

@NgModule({
    declarations: [AdminMainPageComponent, UserSessionRepComponent, UserActivityRepComponent, DocumentTrackingComponent, AdminActionComponent, EditProfileComponent],
    imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      RouterModule,
      MatTableModule,
      MatDialogModule,
      MatInputModule,
      MaterialModule,
      MatFormFieldModule,
      AgGridModule.withComponents([]),
   //   ModalModule.forRoot()
      
    ],
    providers: [
      // ...
      DatePipe, // Ensure DatePipe is listed as a provider here
    ],
    exports:[AdminMainPageComponent]
  
  })
export class AdminPageModule { }