import { EventEmitter, Injectable, Output } from '@angular/core';
import { API_BASE_URL } from './auth.constant';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root'
})
export class ShareDocumentsService {
  @Output() aClickedEvent = new EventEmitter<string>();
  @Output() checkedEvent = new EventEmitter<string>();
 static capturedImageupload = API_BASE_URL + '/api/private/caregiver/uploadedDocs';
 static uploadDocuments = API_BASE_URL + '/api/private/caregiver/uploadCaregiverDoc';
 static updateUploadedDoc = API_BASE_URL + '/api/private/caregiver/updateCaregiverDocument';
 static uploadDocumentInfo = API_BASE_URL + '/api/private/caregiver/updateUploadedDocInfo';
 static updateDocumentInfo = API_BASE_URL + '/api/private/caregiver/updateDocumentInfo';
 static deleteUploadedDoc = API_BASE_URL + '/api/private/caregiver/deleteUploadedDocument';
 static getmyDocuments = API_BASE_URL + '/api/private/document/nurse/getMyDocuments';
 static searchCaregiver = API_BASE_URL + '/api/private/document/searchCaregivers';
 static findNurseDocuments = API_BASE_URL + '/api/private/document/findNurseDocuments';
 static requestDocuments = API_BASE_URL + '/api/private/document/requestForDocAccess';
 static getrequestedDocs = API_BASE_URL + '/api/private/document/getMyDocumentRequests';
 static caregiverAction = API_BASE_URL + '/api/private/document/submitCaregiverAction';
 static serachRecruiter = API_BASE_URL + '/api/private/caregiver/searchRecruiter';
 static SEND_DOCS_TO_NON_REG_REC_EMAIL = API_BASE_URL + '/api/private/document/sendDocumentsToEmailId';
 static senddocuments = API_BASE_URL + '/api/private/document/sendDocumentsToRecruiter';
 static recruiterAccessdocs = API_BASE_URL + '/api/private/document/getRecruiterAccessibleDocs';
 static downloadDocuments = API_BASE_URL + '/api/private/document/downloadDocument';
 static downloadDocumentswithActivity = API_BASE_URL + '/api/private/document/downloadDocumentwithActivity';
 static bagdeCount = API_BASE_URL + '/api/private/document/getBadgeCount';
 static deletDoc = API_BASE_URL + '/api/private/document/deleteNurseDocument';
 static multipleDelDoc = API_BASE_URL + '/api/private/document/deleteMultipleNurseDocument';
  private messageSource = new BehaviorSubject('default message');
  currentMessage = this.messageSource.asObservable();
  public checked ;
  public documentId=[];
  public caregiverdocumentId =[];
  public enablecheckandbtn:boolean;
  public senddocuments:boolean;
  public approvedocuments:boolean;
  public response:any;
  public alert;
  public isAlert:boolean;
  public src;
  public type;
  sendData:EventEmitter<any>
  alertData:EventEmitter<any>
  viewFile:EventEmitter<any>
  selectData:EventEmitter<any>
  badgeCount:EventEmitter<any>
  editData:EventEmitter<any>
  shareDoc:EventEmitter<any>
  paginationChange:EventEmitter<any>
  constructor(private httpClient: HttpClient,
    private alertService: AlertService) { 
    this.sendData = new EventEmitter<any>();
    this.alertData = new EventEmitter<any>();
    this.viewFile = new EventEmitter<any>();
    this.selectData = new EventEmitter<any>();
    this.badgeCount = new EventEmitter<any>();
   this.editData = new EventEmitter<any>();
   this.shareDoc = new EventEmitter<any>();
   this.paginationChange = new EventEmitter<any>();
   if(localStorage.getItem('jwt') !=null){
    sessionStorage.setItem("access_token", localStorage.getItem('jwt'));
  }
  }
  alertEvent(){
    this.alertData.emit();
  }
  raiseEvent(){
    this.sendData.emit();
  //  console.log("service");
  }
  raiseEditEvent(documentsData:any){
    this.editData.emit(documentsData);
  }
  fileEvent(data:any){
    this.viewFile.emit(data);
  }
  selectEvent(){
    this.selectData.emit();
  }
  shareDocEvent(documentData:any){
    this.shareDoc.emit(documentData);
  }
  badgeCountEvent(){
    this.badgeCount.emit();
  }
  paginationEvent(){
    this.paginationChange.emit();
  }
  Aclicked(msg:any){
    this.aClickedEvent.emit(msg);
    if(this.checked ){
      this.caregiverdocumentId.push(msg);
    }
    else {
      var removeIndex1 = this.caregiverdocumentId.map(function(item){return item;}).indexOf(msg);
      if(removeIndex1 != -1){
        this.caregiverdocumentId.splice(removeIndex1, 1);
      }
    }
  }
  aclicked(msg:any){
    this.aClickedEvent.emit(msg);
    
    if(this.checked){
      this.documentId.push(msg);
    }
      else {
      var removeIndex = this.documentId.map(function(item){return item;}).indexOf(msg);
      if(removeIndex != -1){
        this.documentId.splice(removeIndex, 1);
      }
    }
    

  }
  private handleError(errorResponse: HttpErrorResponse){
    if(errorResponse.error instanceof ErrorEvent){
   // console.error('Client Side Error: ', errorResponse.error.message);
    } else {
   // console.error('Server Side Error: ', errorResponse);
    }
     
     return throwError(errorResponse);
    }
  capturedImageUpload(docId, formData){
    let authToken = localStorage.getItem('jwt');
  
  let headers = new HttpHeaders()
  headers.append('Content-Type', 'multipart/form-data')
 headers.append('Accept', 'application/json')
  headers.append('Authorization', 'Bearer ' + authToken);
  //console.log(headers);
  return this.httpClient.post(ShareDocumentsService.capturedImageupload , formData, {headers, responseType:'text'})
  .pipe(map((res)=>{
 return res
  })
  
  )
  
  }
uploadDocuments(formData):Observable<any>{ /** for upload document */
  let authToken = localStorage.getItem('jwt');
  
  let headers = new HttpHeaders()
  headers.append('Content-Type', 'multipart/form-data')
  headers.append('Accept', 'application/json')
  headers.append('Authorization', 'Bearer ' + authToken);
 // console.log(headers);
  return this.httpClient.post(ShareDocumentsService.uploadDocuments , formData, {headers})
  .pipe(
  catchError(err=>{
    
    return err;
  })
  )
}
updateUploadDocuments(formData):Observable<any>{ /** for edit uploaded document */
  let authToken = localStorage.getItem('jwt');
  
  let headers = new HttpHeaders()
  headers.append('Content-Type', 'multipart/form-data')
  headers.append('Accept', 'application/json')
  // headers.append('Content-Type', 'multipart/form-data')
  // headers.append('Accept', 'application/json')
  headers.append('Authorization', 'Bearer ' + authToken);
 // console.log(headers);
  return this.httpClient.post(ShareDocumentsService.updateUploadedDoc ,formData, {headers})
  .pipe(
  catchError(err=>{
    
    return err;
  })
  )
}
uploadDocumentInfo(data):Observable<any>{ /** upload document details */
  let authToken = localStorage.getItem('jwt');
  
  let headers = new HttpHeaders()
  headers.append('Accept', 'application/json')
  headers.append('Authorization', 'Bearer ' + authToken);
 // console.log(headers);
  return this.httpClient.post(ShareDocumentsService.uploadDocumentInfo, data, {headers} )
  .pipe(map((res : any)=>{
    return res.responseMessage;
  }), 
  catchError(err=>{
    this.alertService.error('Sorry, Document upload failed');
    return err;
  })
  )
  
}
updateUploadDocumentInfo(data){ /** upload document details for update*/
  let authToken = localStorage.getItem('jwt');
 
  let headers = new HttpHeaders()
  headers.append('Accept', 'application/json')

  headers.append('Authorization', 'Bearer'+authToken);
 // console.log(headers);
  return this.httpClient.post(ShareDocumentsService.updateDocumentInfo , data, {headers} )
  .pipe(map((res : any) =>{
    return res.responseMessage;
  }))
}
deleteUploadedDocuments(path:any){
  let authToken =localStorage.getItem('jwt');
  
  let headers = new HttpHeaders()
  headers.append('Accept', 'application/json')
  headers.append('Authorization', 'Bearer ' + authToken);
 // console.log(headers);
  return this.httpClient.post(ShareDocumentsService.deleteUploadedDoc , path, {headers} )
  .pipe(map(res=>{
    return res;
  }))
}
getMyDocuments(){ /** integration to get user documents */
  let authToken = localStorage.getItem('jwt');
  const headers = new HttpHeaders()
  headers.append('Content-Type', 'application/json')
  headers.append('Authorization', 'Bearer'+authToken);
  return this.httpClient.get(ShareDocumentsService.getmyDocuments, {headers:headers, observe:'response'})
  .pipe(map((res: any) => {
    if (res) {
     // console.log("response "+JSON.stringify(res));
      return res;
    }
    return null;
  }),
  catchError(this.handleError)
  );
}
deletDocuments(caregiverDocId){
  let authToken = localStorage.getItem('jwt');
  let params = new HttpParams().append("caregiverDocId",caregiverDocId)
  const headers = new HttpHeaders()
  headers.append('Content-Type', 'application/json')
  headers.append('Authorization', 'Bearer'+authToken);
  return this.httpClient.delete(ShareDocumentsService.deletDoc, {params:params, headers})
  .pipe(map((res: any) => {
    if (res) {
    //  console.log("response "+JSON.stringify(res));
      return res;
    }
    return null;
  }));
}
multipleDeletDocuments(caregiverDocIds){
  let authToken = localStorage.getItem('jwt');
  let params = new HttpParams().append("caregiverDocIds",caregiverDocIds)
  const headers = new HttpHeaders()
  headers.append('Content-Type', 'application/json')
  headers.append('Authorization', 'Bearer'+authToken);
  return this.httpClient.delete(ShareDocumentsService.multipleDelDoc, {params:params, headers})
  .pipe(map((res: any) => {
    if (res) {
    //  console.log("response "+JSON.stringify(res));
      return res;
    }
    return null;
  }));
}
searchCargiver(searchcargiver: string){
  let authToken = localStorage.getItem('jwt');
  let params = new HttpParams().append("searchString",searchcargiver)
  const headers = new HttpHeaders()
  headers.append('Content-Type', 'application/json')
  headers.append('Authorization', 'Bearer'+authToken);

  return this.httpClient.get(ShareDocumentsService.searchCaregiver, {params:params, headers, observe: 'response'})
      .pipe(map((res: any) => {
        if (res) {
        //  console.log("response "+JSON.stringify(res));
          return res;
        }
        return null;
      }),
      catchError(this.handleError)
      );
}

findNurseDocs(nurseId) {
 
  let authToken = localStorage.getItem('jwt');

  let params = new HttpParams().append("nurseId",nurseId);
  const headers = new HttpHeaders()
  headers.append('Content-Type', 'application/json')
  headers.append('Authorization', 'Bearer'+authToken);

  return this.httpClient.get(ShareDocumentsService.findNurseDocuments, {params, headers})
      .pipe(map((res: any) => {
        if (res) {
        //  console.log("response "+JSON.stringify(res));
          return res;
        }
        return null;
      }));
}
badgeCounts(){
  let authToken = localStorage.getItem('jwt');
  const headers = new HttpHeaders()
  headers.append('Content-Type', 'application/json')
  headers.append('Authorization', 'Bearer'+authToken);
  return this.httpClient.get(ShareDocumentsService.bagdeCount, {headers, observe:'response'})
  .pipe(map((res:any)=>{
    if (res) {
    //  console.log("response "+JSON.stringify(res));
      this.response = JSON.stringify(res);
      this.alert = res.recruiterDocPermissionCount;
      return res;
    }
    return null;
  }),
  catchError(this.handleError)
  );
}
requestDocs(requiredIds:any){
  let authToken = localStorage.getItem('jwt');
  const headers = new HttpHeaders()
  headers.append('Content-Type', 'application/json')
  headers.append('Authorization', 'Bearer'+authToken);
  return this.httpClient.post(ShareDocumentsService.requestDocuments,requiredIds,{headers})
  .pipe(map((res:any)=>{
    if(res){
    //  console.log("response "+res);
      return res;
    }
    return null;
  }))
}
caregiverReqDocs(){
  let authToken = localStorage.getItem('jwt');

  //let params = new HttpParams()headers.append("nurseId",nurseId);
  const headers = new HttpHeaders()
  headers.append('Content-Type', 'application/json')
  headers.append('Authorization', 'Bearer'+authToken);

  return this.httpClient.get(ShareDocumentsService.getrequestedDocs, {headers, observe:'response'})
      .pipe(map((res: any) => {
        if (res) {
         // console.log("response "+JSON.stringify(res));
          return res;
        }
        return null;
      }),
      catchError(this.handleError)
      );
}
submitCaregiverAction(requiredDetails){
  let authToken = localStorage.getItem('jwt');
  const headers = new HttpHeaders()
  headers.append('Content-Type', 'application/json')
  headers.append('Authorization', 'Bearer'+authToken);
  return this.httpClient.post(ShareDocumentsService.caregiverAction, requiredDetails, {headers})
  .pipe(map((res:any)=>{
    if(res){
     // console.log("response "+res);
      return res;
    }
    return null;
  }))

}
recruiterAccessibleDocs(){
  let authToken = localStorage.getItem('jwt');

  //let params = new HttpParams()headers.append("nurseId",nurseId);
  const headers = new HttpHeaders()
  headers.append('Content-Type', 'application/json')
  headers.append('Authorization', 'Bearer'+authToken);

  return this.httpClient.get(ShareDocumentsService.recruiterAccessdocs, {headers, observe:'response'})
      .pipe(map((res: any) => {
        if (res) {
         // console.log("response "+JSON.stringify(res));
          return res;
        }
        return null;
      }),
      catchError(this.handleError)
      );
}
searchRecruiter(searchRecruiter:string){ /** integration for search recruiter */
  let authToken = localStorage.getItem('jwt');
  let params = new HttpParams().append("searchString",searchRecruiter)
  const headers = new HttpHeaders()
  headers.append('Content-Type', 'application/json')
  headers.append('Authorization', 'Bearer'+authToken);

  return this.httpClient.get(ShareDocumentsService.serachRecruiter, {params:params, headers})
      .pipe(map((res: any) => {
        if (res) {
         // console.log("response "+JSON.stringify(res));
          this.enablecheckandbtn = true;
          return res;
        }
        return null;
      }));
}
sendDocToNonRegRecEmail(requiredIds){
  let authToken = localStorage.getItem('jwt');
  const headers = new HttpHeaders()
  headers.append('Content-Type', 'application/json')
  headers.append('Authorization', 'Bearer'+authToken);
  return this.httpClient.post(ShareDocumentsService.SEND_DOCS_TO_NON_REG_REC_EMAIL, requiredIds, {headers})
  .pipe(map((res:any)=>{
    if(res){
     // console.log("response "+res);
      return res;
    }
    return null;
  }))
}
sendDocuments(requiredIds){ /** integration for send documents to recruiter */
  let authToken = localStorage.getItem('jwt');
  const headers = new HttpHeaders()
  headers.append('Content-Type', 'application/json')
  headers.append('Authorization', 'Bearer'+authToken);
  return this.httpClient.post(ShareDocumentsService.senddocuments,requiredIds,{headers})
  .pipe(map((res:any)=>{
    if(res){
     // console.log("response "+res);
      return res;
    }
    return null;
  }))
}

  
downloadDocument(caregiverDocId, activity) {
  let authToken = localStorage.getItem('jwt');
  const headers = new HttpHeaders()
  headers.append('Content-Type', 'application/json')
  headers.append('Authorization', 'Bearer'+authToken);
  let params = new HttpParams().append("activity",activity)
    return this.httpClient.get(ShareDocumentsService.downloadDocumentswithActivity+'?caregiverDocId='+caregiverDocId, 
      {params:params, headers,responseType: 'blob' as 'json', observe: 'response'});
}
  changeMessage(message) {
    this.messageSource.next(message)
  }
}


