import { Component, OnInit } from '@angular/core';
@Component({
    selector: 'app-for_recruiter',
    templateUrl: './recruiter.component.html',
    styleUrls: ['./recruiter.component.css']
  })
export class RecruiterComponent implements OnInit {
    
    constructor(){

    }
    ngOnInit() {
      document.body.style.backgroundImage = "none";
      window.scrollTo( 0, 0 );
      setTimeout(() =>document.getElementsByTagName('html')[0].scrollTop=0, 500);
    document.getElementsByTagName('html')[0].scrollTop=0;
    }
    signUpButton() {
      sessionStorage.setItem('isCaregiverOrRecruiter', 'recruiter');
     }
}
