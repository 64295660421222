<div class="security-container">
    <div class="image-container">
        <i style="font-size: 450px;
        color: #003c6c;" class="fa fa-lock"></i>
        <!--<img src="../../../assets/lock_2.png">-->
        </div>
        <div class="text-container">
           <h1> Data Security</h1>
           <p>We plan our products, processes, and infrastructure with security in mind so that our caregivers can rest
                assured that their data will remain protected.</p>
<h3>Key Security Features</h3>
<ul>
<li>
    TLS encryption to ensure a secure to our servers and data transmission</li>
<li>
    
    Front-end web application acceptance to prevent URL-based tampering</li>
<li>
    Data-at-rest encryption</li>
<li>
    256-bit AES encryption</li>
<li>
    Strict standards for security and privacy</li>
<li>
    We will never disclose your information to any third parties</li>
</ul>
</div>
    </div>