import {
  Component,
  Input,
  Inject,
  OnInit,
  ViewChild,
  AfterViewInit,
  Output,
  EventEmitter,
  ViewEncapsulation,
  TemplateRef,
} from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import * as moment from 'moment'; 

import { ShareDocumentsService } from '../../shared-services/share-documents.service';
import {
  CustomValidators,
  errorMessages,
  MyErrorStateMatcher,
} from '../../documents/add-documents/date.match';
import { AuthenticationService } from 'src/app/shared-services/authentication.service';
import { Observable } from 'rxjs';
import { HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { formatDate } from '@angular/common';
import { AlertService } from 'src/app/shared-services/alert.service';
import { AdminService } from 'src/app/shared-services/adminservice.service';

@Component({
  selector: 'app-add-documents',
  templateUrl: './add-documents.component.html',
  styleUrls: ['./add-documents.component.scss']
})
export class AddDocumentsComponent implements OnInit {
  newDocumentForm: FormGroup;
  uploadFileForm: FormGroup;
  postedValues: any;
  matcher = new MyErrorStateMatcher();
  errors = errorMessages;
  documentNames: any;
  uploadedDocsName:any;
  documentTypes;
  docId: number;
  uploadedDocument;
  uploadedDocInfo:any ={};
  isloading:boolean;
  public file:any ={};
  progress: number = 0;
  public uploadText;
  public isFilename:boolean;
  public isDelete:boolean;
  public isDone:boolean;
  public respone;
  public dates;
  public isUpdate:boolean;
  public documentsData;
  public isOpenDates:boolean;
  public isUploaded:boolean;
  public text;
  public isEdit;
  public isExpDateMand:boolean;
  public isCancelBtn:boolean;
  public previousDocumentStoredPathWithSlice;
  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };
  @ViewChild("notifications") notifications:TemplateRef<any>;
  @ViewChild("isDocExpired") isDocExpired:TemplateRef<any>;
  @ViewChild("isupdateDocExpired") isupdateDocExpired:TemplateRef<any>;
  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private shareDocumentsService: ShareDocumentsService,
    private alertService: AlertService,
    private adminService: AdminService,
    private authenticationService: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // if(data.uploadFileForm !=null || data.uploadFileForm !=undefined){
    //   this.fileChange(data);
    // }
    this.createDocumentForm();
    this.getDocumentName();
    // this.uploadFileForm = this.formBuilder.group({
    //   uploadDocument: [null, [Validators.required]],

    // })
     this.shareDocumentsService.editData.subscribe((res)=>{ /** subscribe when user click on edit icon in home screen table (action column) */
       this.isUpdate = true;
       this.isEdit = true;
       this.isOpenDates =true;
      //console.log('data', res);
      this.documentsData = res;
      this.isFilename = true;
      //slice(11, -1)
      this.file.name = res.documentStoredPath.slice(11);
      this.previousDocumentStoredPathWithSlice = res.documentStoredPath.slice(11);
      //this.fileChanges(this.file);
      this.newDocumentForm.controls['uploadFileForm'].get('uploadDocument').clearValidators();
      this.newDocumentForm.controls['uploadFileForm'].get('uploadDocument').updateValueAndValidity();
      sessionStorage.setItem("previousDocumentStoredPath", res.documentStoredPath);
      
      
      this.newDocumentForm.controls['description'].setValue(res.documentDescription);
      this.docId = res.docTypeId;
     // let expiryDate = this.dateformatter(res.expirationDate);
      //let uploadDate = this.dateformatter(res.startDate);
      if(res.expirationDate !=null && res.expirationDate !="" ){
        this.newDocumentForm.controls['dateGroup'].get('expiryDate').setValue(formatDate(res.expirationDate,'yyyy-MM-dd','en'));
        sessionStorage.setItem("previousExpirationDate", formatDate(res.expirationDate,'yyyy-MM-dd','en'));
      }else{
        this.newDocumentForm.controls['dateGroup'].get('expiryDate').setValue("");
        sessionStorage.setItem("previousExpirationDate", "");
      }
      
      if(res.startDate !=null && res.startDate !=""){
        sessionStorage.setItem("previousStartDate", formatDate(res.startDate,'yyyy-MM-dd','en'));
      this.newDocumentForm.controls['dateGroup'].get('uploadDate').setValue(formatDate(res.startDate,'yyyy-MM-dd','en'));
      }else{
      sessionStorage.setItem("previousStartDate", "");
      this.newDocumentForm.controls['dateGroup'].get('uploadDate').setValue("");
      }
      // }else{
      //   sessionStorage.setItem("previousStartDate", formatDate(res.startDate,'yyyy-MM-dd','en'));
      // }
      this.newDocumentForm.controls['documentType'].setValue(res.documentType);
      if (res.documentType === "Certification" || res.documentType === "License/Certificate") {
        this.isExpDateMand = true;
        this.newDocumentForm.get('dateGroup.expiryDate').setValidators([Validators.required]);
      } else {
        this.isExpDateMand = false;
        this.newDocumentForm.get('dateGroup.expiryDate').clearValidators();
      }
      
      // After setting the validators, you need to update the control's validation state
      this.newDocumentForm.get('dateGroup.expiryDate').updateValueAndValidity();
  })
  
  }
  dateformatter(params){ /** for date format */
      var dateSplit = params.value.split("T");
      var dateSplit1 = dateSplit[0].split("-");
      var date = dateSplit1[2];
      var month = dateSplit1[1];
      var year = dateSplit1[0];
      var fullDate = date.concat("/", month, "/", year);
      return fullDate;
     // return moment(params.value).format('DD/MM/YYYY'); 
  }
  ngOnInit() {
    sessionStorage.setItem('isNextTab', 'yes');
    document.body.style.backgroundImage = "none";
   // this.uploadText ='Upload';
    this.shareDocumentsService.currentMessage.subscribe(
      (message) => (this.postedValues = message)
    );
    
  }

  createDocumentForm() { /** upload/edit document form */
    this.newDocumentForm = this.formBuilder.group({
      documentName: [null, [Validators.required]],
      documentType:[null, [Validators.required]],
      description:[null, [ Validators.maxLength(100)]],
      uploadFileForm : this.formBuilder.group({
        uploadDocument: [null, [Validators.required]], 
      }),
      dateGroup: this.formBuilder.group(
        {
          expiryDate: [null, []],
          uploadDate: [null, []],
        },
        {
          validator: CustomValidators.checkDates,
        }
      )
    });
  }

  getDocumentName() { /** get document names */
    if (!this.documentNames || this.documentNames.length === 0) {
      this.authenticationService
        .getDocumentNames()
        .subscribe((documentsObj) => {
          if (documentsObj) {
            this.documentNames = documentsObj.body;
            this.documentNames.sort(function(a, b) {
              var nameA = a.documentName.toUpperCase(); // ignore upper and lowercase
              var nameB = b.documentName.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
            
              // names must be equal
              return 0;
            });
            if(this.isUpdate){
              const toSelectDocName = this.documentNames.find(c => c.documentName == this.documentsData.documentName);
      this.newDocumentForm.controls['documentName'].setValue(toSelectDocName.documentName);
            }
          }
        });
    }
  }
 
  populatedocType(event) { /** for document type */
    let i = event.value;
    for(let a=0; a<this.documentNames.length; a++){
      if(this.documentNames[a].documentName === i){
        this.documentTypes = this.documentNames[a].documentType;
        this.newDocumentForm.controls['documentType'].setValue( this.documentNames[a].documentType);

        this.docId = this.documentNames[a].id;
      }
    }
    // if(this.documentTypes =="Certification" || this.documentTypes =="License/Certificate"){
    //   this.isExpDateMand = true;
    //   this.newDocumentForm.controls['dateGroup'].get('expiryDate').setValidators([Validators.required])

    // }else{
    //   this.isExpDateMand = false;
    //   this.newDocumentForm.controls['dateGroup'].get('expiryDate').setValidators(null)
    // }
    if (this.documentTypes === "Certification" || this.documentTypes === "License/Certificate") {
      this.isExpDateMand = true;
      this.newDocumentForm.get('dateGroup.expiryDate').setValidators([Validators.required]);
    } else {
      this.isExpDateMand = false;
      this.newDocumentForm.get('dateGroup.expiryDate').clearValidators();
    }
    
    // After setting the validators, you need to update the control's validation state
    this.newDocumentForm.get('dateGroup.expiryDate').updateValueAndValidity();
    
  }
  uploadFile(){ /** upload selected file */
    this.isloading = true;
    if(!this.isUpdate){ /** for upload file not for edit */
    this.shareDocumentsService
    .uploadDocuments(this.uploadedDocument)
    .subscribe((result) => {
    //  this.uploadedDocsName.push(result.documentStoredPath);
      sessionStorage.setItem('uploadedDocs', this.uploadedDocsName);
      this.isDone = true;
      //  console.log('User successfully created!', result);
        this.respone = result;
        this.isOpenDates = true;
        this.uploadedDocInfo.documentStoredPath = this.respone.documentStoredPath;
        this.newDocumentForm.controls['dateGroup'].get('expiryDate').setValue(this.respone.expirationDate);
    this.newDocumentForm.controls['dateGroup'].get('uploadDate').setValue(this.respone.startDate);
    setTimeout(() => {
      this.progress = 0;
    }, 1500);
    this.isDelete = true;
    this.isloading = false;
          // this.uploadedDocInfo.documentStoredPath = event.body.documentStoredPath;
      //this.newDocumentForm.controls['dateGroup'].get('expiryDate').setValue(event.expirationDate);
     // this.newDocumentForm.controls['dateGroup'].get('uploadDate').setValue(event.uploadedDate);
      return result;
    },
    error=>{

    });
  }
  else{ /** edit uploaded file */
    this.shareDocumentsService.updateUploadDocuments(this.uploadedDocument)
    .subscribe((result) => {
      this.isDone = true;
        this.respone = result;
        this.isOpenDates = true;
        sessionStorage.setItem("newDocumentStoredPath", result.responseData.documentStoredPath);
       // this.uploadedDocInfo.documentStoredPath = result.responseData.documentStoredPath;
        this.newDocumentForm.controls['dateGroup'].get('expiryDate').setValue(this.respone.responseData.expirationDate);
    this.newDocumentForm.controls['dateGroup'].get('uploadDate').setValue(this.respone.responseData.startDate);
    setTimeout(() => {
      this.progress = 0;
    }, 1500);
    this.isDelete = true;
    this.isloading = false;
          // this.uploadedDocInfo.documentStoredPath = event.body.documentStoredPath;
      //this.newDocumentForm.controls['dateGroup'].get('expiryDate').setValue(event.expirationDate);
     // this.newDocumentForm.controls['dateGroup'].get('uploadDate').setValue(event.uploadedDate);
      return result;
    },
    error=>{

    });
  }
  }
  deleteDocument(value){ /** delete selected file if replace document */
   // this.file.name = '';
   let inputObj:any={} ;
   if(this.respone.documentStoredPath !=null && (sessionStorage.getItem("previousDocumentStoredPath") == null || sessionStorage.getItem("previousDocumentStoredPath") == undefined)){
    inputObj.documentStoredPath = this.respone.documentStoredPath;
   inputObj.expirationDate = this.respone.expirationDate;
   inputObj.uploadedDate = this.respone.uploadedDate;
   }else if(sessionStorage.getItem("previousDocumentStoredPath") != null && sessionStorage.getItem("previousDocumentStoredPath") != undefined && value == "deletePreviousDocument"){
    inputObj.documentStoredPath = sessionStorage.getItem("previousDocumentStoredPath");
    inputObj.expirationDate = sessionStorage.getItem("previousExpirationDate");
    inputObj.startDate = sessionStorage.getItem("previousStartDate");
   }
   else{
    inputObj.documentStoredPath = this.respone.responseData.documentStoredPath;
    inputObj.expirationDate = this.respone.responseData.expirationDate;
    inputObj.uploadedDate = this.respone.responseData.uploadedDate;
    inputObj.oldDocumentStoredPath = sessionStorage.getItem("previousDocumentStoredPath")
    inputObj.caregiverDocId = this.documentsData.caregiverDocId;
    inputObj.nurseId = this.documentsData.nurseId
   }
   this.shareDocumentsService.deleteUploadedDocuments(inputObj).subscribe((res)=>{
    // console.log(res);
    // this.isFilename = false;
     //this.uploadFileForm.get('uploadDocument').setValue('');
    // this.newDocumentForm.controls['dateGroup'].get('expiryDate').setValue('');
    // this.newDocumentForm.controls['dateGroup'].get('uploadDate').setValue('');
      this.isDone = false;
      this.isDelete = false;
     return res;
   })


  }
  submitNewDocument(values) { /** submit document details with selected file path */
    this.postedValues = values;
    let today = formatDate(new Date(),'yyyy-MM-dd','en');
    // this.postedValues = this.newDocumentForm.get('documentName').value;
    this.shareDocumentsService.changeMessage(values);
    
if(!this.isUpdate){ /** submit document details  */
  if (values.dateGroup.expiryDate < today && values.dateGroup.expiryDate !=""){
    this.dialog.open(this.isDocExpired);
  }
  else{
  this.uploadedDocInfo.expirationDate = values.dateGroup.expiryDate;
    this.uploadedDocInfo.startDate = values.dateGroup.uploadDate;
   // this.uploadedDocInfo.documentDescription = this.newDocumentForm.get('description').value;
    if(this.newDocumentForm.get('description').value == null || this.newDocumentForm.get('description').value == ""){
      this.uploadedDocInfo.documentDescription ="";
    }else{
    this.uploadedDocInfo.documentDescription = this.newDocumentForm.get('description').value;
    }
  this.uploadedDocInfo.docTypeId  = this.docId;
  if(localStorage.getItem('Authority') === "ADMIN"){
    this.uploadedDocInfo.nurseId = this.data.userId;
    this.adminService.uploadDocumentInfoByAdmin(this.uploadedDocInfo).subscribe((res)=>{
      window.scrollTo( 0, 0 );
      document.getElementsByTagName('html')[0].scrollTop=0;
      if(res == "File Upload Sucessful"){
        this.shareDocumentsService.raiseEvent();
        this.alertService.success('Document Uploaded Successfully', this.options);
      }
     else{
      this.alertService.error('Sorry, Document upload failed', this.options);
     }
      return res;
  
    })
  }else{
  this.shareDocumentsService.uploadDocumentInfo(this.uploadedDocInfo).subscribe((res)=>{
    window.scrollTo( 0, 0 );
    document.getElementsByTagName('html')[0].scrollTop=0;
    if(res == "File Upload Sucessful"){
      this.shareDocumentsService.raiseEvent();
      this.alertService.success('Document Uploaded Successfully', this.options);
    }
   else{
    this.alertService.error('Sorry, Document upload failed', this.options);
   }
    return res;

  })}
}
}
else{ /** submit document details for edit */
  
  if (values.dateGroup.expiryDate < today && values.dateGroup.expiryDate !=""){
    this.dialog.open(this.isupdateDocExpired);
  }
  else{
  this.uploadedDocInfo.caregiverDocId  = this.documentsData.caregiverDocId;
  this.uploadedDocInfo.docTypeId  = this.docId;
  this.uploadedDocInfo.expirationDate = values.dateGroup.expiryDate;
    this.uploadedDocInfo.startDate = values.dateGroup.uploadDate;
    if(this.newDocumentForm.get('description').value == null || this.newDocumentForm.get('description').value == ""){
      this.uploadedDocInfo.documentDescription ="";
    }else{
    this.uploadedDocInfo.documentDescription = this.newDocumentForm.get('description').value;
    }
    this.uploadedDocInfo.documentStoredPath = sessionStorage.getItem("newDocumentStoredPath");
    this.uploadedDocInfo.oldDocumentStoredPath = sessionStorage.getItem("previousDocumentStoredPath");
 this.shareDocumentsService.updateUploadDocumentInfo(this.uploadedDocInfo).subscribe((res)=>{
  if(sessionStorage.getItem("previousDocumentStoredPath") != this.documentsData.documentStoredPath){
  this.deleteDocument("deletePreviousDocument");
 }
  window.scrollTo( 0, 0 );
  document.getElementsByTagName('html')[0].scrollTop=0;
  if(res == "Data Updated Successfully"){
    this.shareDocumentsService.raiseEvent();
    this.alertService.success('Document updated Successfully', this.options);
  }
 else{
  this.alertService.error('Sorry, Document update failed', this.options);
 }
   // console.log(res);
    //this.shareDocumentsService.raiseEvent();
    sessionStorage.removeItem("newDocumentStoredPath");
    return res;
  })
}
}
     
  }
  submitExpiredDocument(values){
    this.dialog.closeAll();
    this.uploadedDocInfo.expirationDate = values.dateGroup.expiryDate;
    this.uploadedDocInfo.startDate = values.dateGroup.uploadDate;
   /// this.uploadedDocInfo.documentDescription = this.newDocumentForm.get('description').value;
   if(this.newDocumentForm.get('description').value == null || this.newDocumentForm.get('description').value == ""){
    this.uploadedDocInfo.documentDescription ="";
  }else{
  this.uploadedDocInfo.documentDescription = this.newDocumentForm.get('description').value;
  }
  this.uploadedDocInfo.docTypeId  = this.docId;
  if(localStorage.getItem('Authority') === "ADMIN"){
    this.uploadedDocInfo.nurseId = this.data.userId;
    this.adminService.uploadDocumentInfoByAdmin(this.uploadedDocInfo).subscribe((res)=>{
      window.scrollTo( 0, 0 );
      document.getElementsByTagName('html')[0].scrollTop=0;
      if(res == "File Upload Sucessful"){
        this.shareDocumentsService.raiseEvent();
        this.alertService.success('Document Uploaded Successfully', this.options);
      }
     else{
      this.alertService.error('Sorry, Document upload failed', this.options);
     }
      return res;
  
    })
  }else{
  this.shareDocumentsService.uploadDocumentInfo(this.uploadedDocInfo).subscribe((res)=>{
    window.scrollTo( 0, 0 );
    document.getElementsByTagName('html')[0].scrollTop=0;
    if(res == "File Upload Sucessful"){
      this.shareDocumentsService.raiseEvent();
      this.alertService.success('Document Uploaded Successfully', this.options);
    }
   else{
    this.alertService.error('Sorry, Document upload failed', this.options);
   }
    return res;

  })}
  }
  updateExpiredDocument(values){
    this.dialog.closeAll();
    this.uploadedDocInfo.caregiverDocId  = this.documentsData.caregiverDocId;
    this.uploadedDocInfo.docTypeId  = this.docId;
    this.uploadedDocInfo.expirationDate = values.dateGroup.expiryDate;
      this.uploadedDocInfo.startDate = values.dateGroup.uploadDate;
     // this.uploadedDocInfo.documentDescription = this.newDocumentForm.get('description').value;
     if(this.newDocumentForm.get('description').value == null || this.newDocumentForm.get('description').value == ""){
      this.uploadedDocInfo.documentDescription ="";
    }else{
    this.uploadedDocInfo.documentDescription = this.newDocumentForm.get('description').value;
    }
    this.uploadedDocInfo.documentStoredPath = sessionStorage.getItem("newDocumentStoredPath");
    this.uploadedDocInfo.oldDocumentStoredPath = sessionStorage.getItem("previousDocumentStoredPath");
   this.shareDocumentsService.updateUploadDocumentInfo(this.uploadedDocInfo).subscribe((res)=>{
    if(sessionStorage.getItem("previousDocumentStoredPath") != this.documentsData.documentStoredPath){
      this.deleteDocument("deletePreviousDocument");
     }
    window.scrollTo( 0, 0 );
    document.getElementsByTagName('html')[0].scrollTop=0;
    if(res == "Data Updated Successfully"){
      this.shareDocumentsService.raiseEvent();
      this.alertService.success('Document updated Successfully', this.options);
    }
   else{
    this.alertService.error('Sorry, Document update failed', this.options);
   }
     // console.log(res);
      //this.shareDocumentsService.raiseEvent();
      return res;
    })
  
  }
  closeAll(){
    this.dialog.closeAll();
  }
  
  fileChanges(event) { /**when select file */
  console.log(event);
}

  fileChange(event, formValues) { /**when select file */
  console.log(formValues);
  console.log(formValues.controls.uploadFileForm);
    //const dialogConfig = new MatDialogConfig();
    this.isEdit = false;
   // dialogConfig.disableClose = true;
   if(this.respone !=null){
    this.newDocumentForm.controls['dateGroup'].get('expiryDate').setValue('');
     this.newDocumentForm.controls['dateGroup'].get('uploadDate').setValue('');
    this.deleteDocument("FileChange");
   }
   this.isDone = false;
   this.isloading = false;
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      this.file = fileList[0];
      if(formValues.controls.uploadFileForm !=undefined){

        this.uploadFileForm = formValues.controls.uploadFileForm;
        this.isUploaded = this.uploadFileForm.valid;
        //this.uploadFileForm.controls[''].setValue(this.file.name);
        //this.uploadFileForm.get('uploadDocument').setValue('');
       // this.uploadFileForm.controls['uploadDocument'].setValue("");
      }else{
        this.isUploaded = this.uploadFileForm.valid;
      }
      this.isFilename = true;
      if(this.file.name.indexOf('.pdf') !==-1 || this.file.name.indexOf('.png') !== -1 || this.file.name.indexOf('.jpg') !== -1 || this.file.name.indexOf('.jpeg') !== -1) {
        if(this.file.size > 2e+7){
         // this.dialog.open(this.notifications);
          alert('Provided Image size is more than 20Mb so please make sure it should be equal or less than 20Mb')
        }
        else{
        let formData: FormData = new FormData();
        formData.append('uploadFile', this.file, this.file.name);
        if(this.isUpdate){
          formData.append('docId', this.documentsData.caregiverDocId);
        }
        // this.uploadedDocument = this.file;
        this.uploadedDocument = formData;
        this.isOpenDates = false;
        this.isCancelBtn = true;
        }
      }
      else{
       // this.dialog.open(this.notifications);
        alert("File type should be '.pdf', '.png', '.jpg', 'jpeg'");
       // this.uploadFileForm.get('uploadDocument').setValue('');
        this.newDocumentForm.controls['uploadFileForm'].get('uploadDocument').setValue('');
        this.isFilename = false;
      }
     
    }
  }
  getPreviousData(){
    this.isOpenDates = true;
        this.isCancelBtn = false;
        this.isEdit = true;
        this.file.name = this.previousDocumentStoredPathWithSlice;
  }
  removeFile(){
    if(this.respone !=null){
      this.newDocumentForm.controls['dateGroup'].get('expiryDate').setValue('');
       this.newDocumentForm.controls['dateGroup'].get('uploadDate').setValue('');
     //  sessionStorage.removeItem("previousDocumentStoredPath");
      this.deleteDocument("removeFile");
     }
  }
  close(){
    this.dialog.closeAll();
  }
}
