import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SenddocumentComponent } from './senddocument/senddocument.component';
import { ApproveDocumentsComponent } from './approve-documents/approvedocuments.component';
import { RequestDocumentsComponent } from './request-documents/request-documents.component';
import { UploadDocumentsComponent } from './upload-documents/upload-documents.component';
import { RecruiteraccessDocuments} from './recruiteracess-documents/recruiteraccess-documents.component';
import {MatTableModule} from '@angular/material/table';
import { AgGridModule } from 'ag-grid-angular';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {CaregiverhomeComponent} from './caregiverhome/caregiverhome.component';
import { ActionComponent } from './caregiverhome/action.compoment';
import {MatDialogModule} from '@angular/material/dialog';
import { MaterialModule } from '../material.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DownloadComponent } from './recruiteracess-documents/download.component';
import { from } from 'rxjs';
import { AddDocumentsComponent } from './add-documents/add-documents.component';
import { RouterModule } from '@angular/router';
import { AngularFileViewerModule } from '@taldor-ltd/angular-file-viewer';



@NgModule({
  declarations: [SenddocumentComponent, ApproveDocumentsComponent, UploadDocumentsComponent, CaregiverhomeComponent, ActionComponent ,AddDocumentsComponent, RequestDocumentsComponent, RecruiteraccessDocuments, DownloadComponent],
  
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatTableModule,
    MatDialogModule,
    MatInputModule,
    MaterialModule,
    MatFormFieldModule,
    AngularFileViewerModule,
    AgGridModule.withComponents([]),
 //   ModalModule.forRoot()
    
  ],
  exports:[UploadDocumentsComponent,SenddocumentComponent,ApproveDocumentsComponent, CaregiverhomeComponent, RequestDocumentsComponent, RecruiteraccessDocuments, AddDocumentsComponent]

})
export class DocumentsModule { }

