<link href="//maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet" id="bootstrap-css">
<script src="//maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js"></script>
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">

<div class="wrapper register" id="wrapReg">
  <!--<div class="wrapper fadeInDown ">-->
  <!--<div *ngIf="isError" style="background-color:rgba(255, 255, 255, 0.815);color:maroon;padding:10px;width: 100%;
  margin-bottom: 10px;
  text-align: center;
  border-radius: 5px;">{{error}}</div>-->

  
     
   <div class="dashBoard" *ngIf="!buttonnClieked !! backtoReghboard">  
        <!--  Caregiver Registration Form -->

  <div 
  style="position: relative;
  margin-left: -20px;
  margin-right: 30px;" 
  (click)="Caregiver(); toggleRegHeading() ">
        <button (click)="Caregiver(); toggleRegHeading() " id="catButton1" class="catagoryButton"> <img width="400px" height="400px" (click)="Caregiver()" src="../../../assets/CaregiverIconss-01.png" class="buttonImg"></button>
        <p (click)="Caregiver()" 
        style="text-align: center;
        color: white;
        font-size: 20px;
        cursor: pointer;
        position: absolute;
        bottom: 10%;
        left: 50%;
        transform: translate(-55%, -50%);
        font-family: 'Acumin Variable Concept';">CAREGIVER</p>
        </div>
    <div style="position: relative;" (click)="Recruiter(); toggleRegHeading() ">
        <button (click)="Recruiter(); toggleRegHeading()" id="catButton2" class="catagoryButton"><img width="350px" height="400px" (click)="Recruiter()" src="../../../assets/CaregiverIconss-02.png" class="buttonImg"></button>
        <p (click)="Recruiter()" 
        style="text-align: center;
        color: white;
        font-size: 20px;
        cursor: pointer;
        position: absolute;
        bottom: 10%;
        left: 50%;
        transform: translate(-35%, -50%);
        font-family: 'Acumin Variable Concept';
        ">RECRUITER</p>
        </div>
</div>
<div id="formContent" *ngIf="caregiver || recruiter">
  <div *ngIf="!validateOtc">
    <div class="fadeIn first loginHeading" >
      <span>{{registrationHeadings}}</span>
     </div>
<div class="container-fluid">   

<div id="caregiverSection" *ngIf="caregiver && !recruiter">
  
  <!-- Social Icon -->
 <!--<div class="socialSection">
    <span class="socialSection">Register With</span>
      <a title="Register with Facebook"><i class="fa fa-facebook-official socialIcons" (click)="signInWithFB()" style="font-size:1.4em;color:#3b5998;"></i></a>
      <a title="Register with Google"><i class="fa fa-google socialIcons" (click)="signInWithGoogle()" style="font-size:1.4em;background: conic-gradient(from -45deg, #ea4335 110deg, #4285f4 90deg 180deg, #34a853 180deg 270deg, #fbbc05 270deg) 73% 55%/150% 150% no-repeat;
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        -webkit-text-fill-color: transparent;"></i></a>
      <a href="" title="Register with LinedIn"><i class="fa fa-linkedin-square socialIcons" style="font-size:1.4em;color: #0077b5"></i></a>
      
 </div>-->
 <ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">SMS option Information</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    For SMS option charges might be applicable.
  </div>
</ng-template>
<form   [formGroup]="caregiverForm"  (ngSubmit)="caregiveronSubmit(caregiverForm.value)"  novalidate >
  <div class="row">
    
  
    <div class="col-md-6"><mat-form-field   appearance="outline"> 
      <mat-label style="font-size: 17px;"> First Name *</mat-label>
      <input matInput placeholder="First Name" formControlName="firstname" maxlength="13"  autocomplete="off">
      <mat-error *ngIf="!caregiverForm.controls['firstname'].valid && caregiverForm.controls['firstname'].touched">
       First name is required.
      </mat-error>
    </mat-form-field></div>
    <div class="col-md-6"><mat-form-field   appearance="outline"> 
      <mat-label style="font-size: 17px;"> Last Name *</mat-label>
      <input matInput placeholder="First Name" formControlName="lastname" maxlength="13"  autocomplete="off">
      <mat-error *ngIf="!caregiverForm.controls['lastname'].valid && caregiverForm.controls['lastname'].touched">
      Last name is required.
      </mat-error>
    </mat-form-field></div>
  </div>
  <div class="row">
    <div class="col-md-6"><mat-form-field   appearance="outline"> 
      <mat-label style="font-size: 17px;"> Email *</mat-label>
      <input matInput placeholder="Email" formControlName="username"   autocomplete="off">
      <mat-error *ngIf="!caregiverForm.controls['username'].valid && caregiverForm.controls['username'].touched">
        {{ getErrorEmail() }}
      </mat-error>
    </mat-form-field></div>
    <div class="col-md-6">
      <mat-form-field   appearance="outline"> 
      <mat-label style="font-size: 17px;"> Phone Number <span *ngIf="isEnableStar">*</span></mat-label>
      <input matInput placeholder="Phone Number" formControlName="phoneNumber"   autocomplete="off">
      <mat-error style="width:105%;" *ngIf="!caregiverForm.controls['phoneNumber'].valid && caregiverForm.controls['phoneNumber'].touched">
        {{getErrorPhone()}}
      </mat-error>
    </mat-form-field></div>
  </div>
  <div class="row" formGroupName ="passwordGroup">
    <div class="col-md-6 mb-3" id="passwordFormat" style="display: flex;">
  <mat-form-field appearance="outline"  class="mat-form-field-outline">
    <mat-label>Password *</mat-label>
    <input matInput placeholder="Password" [type]="hidepassword ? 'text' : 'password'" formControlName="password"   autocomplete="off">
    <mat-icon matSuffix (click)="hidepassword = !hidepassword">{{hidepassword ? 'visibility ' : 'visibility_off'}}</mat-icon>
   
  <mat-error *ngIf="caregiverForm.controls['passwordGroup'].get('password').dirty && !caregiverForm.controls['passwordGroup'].get('password').valid" >
    {{errors.PasswordFormat}}
 </mat-error>
 <mat-error *ngIf="!caregiverForm.controls['passwordGroup'].get('password').dirty && caregiverForm.controls['passwordGroup'].get('password').touched && !caregiverForm.controls['passwordGroup'].get('password').valid" >
  {{errors.Password}}
</mat-error>
  </mat-form-field>
  <div><i style="padding: 5px;
  color: #ffffff;font-size:18px" class="fa fa-info-circle tip"><span><p style="color:black;font-weight:bold;font-size:16px">Passwords must contain:</p>
<p style="color:black;">1. Minimum of 8 characters</p>
<p style="color:rgb(5, 5, 5);">2. one upper case, lower case letter, one special character(!, @, #, $, %, ^, &, *) and one number</p></span></i>
</div>
  </div>
  <div class="col-md-6 mb-3" style="display: flex;">
  <mat-form-field appearance="outline"  class="mat-form-field-outline">
    <mat-label>Confirm Password *</mat-label>
    <input matInput placeholder="Confirm Password" [type]="hidepassword1 ? 'text' : 'password'" formControlName="confirmPassword" autocomplete="off" [errorStateMatcher]="matcher">
      <mat-icon matSuffix (click)="hidepassword1 = !hidepassword1">{{hidepassword1 ? 'visibility ' : 'visibility_off'}}</mat-icon> 
      <mat-error *ngIf="caregiverForm.controls['passwordGroup'].get('password').dirty" >
        {{errors.ConfirmPassword}}
     </mat-error>
     <mat-error *ngIf="!caregiverForm.controls['passwordGroup'].get('password').dirty && caregiverForm.controls['passwordGroup'].get('confirmPassword').touched && !caregiverForm.controls['passwordGroup'].get('password').valid" >
      {{errors.cnfmPassword}}
    </mat-error>
  </mat-form-field>
  </div>
  </div>
  <div class="row" style="margin-left: auto;
  margin-right: auto;
  width: 60%;">
    <mat-form-field   appearance="outline"> 
      <mat-label style="font-size: 17px;"> Profession *</mat-label>
      <mat-select matInput   placeholder="Choose Profession" formControlName="profession" (selectionChange)="populateSpecialties($event)"  autocomplete="off">
        <mat-option>Choose Profession</mat-option>
      <mat-option value="{{profession.id}}" *ngFor="let profession of professions" [ngValue]="profession.id"> {{profession.professionName}}</mat-option>
      </mat-select>
      <mat-error *ngIf="!caregiverForm.controls['profession'].valid && caregiverForm.controls['profession'].touched">
       profession is required.
      </mat-error>
    </mat-form-field>

  </div>
  <div class="row" style="margin-left: auto;
  margin-right: auto;
  width: 60%;">
    <mat-form-field   appearance="outline"> 
      <mat-label style="font-size: 17px;"> Speciality *</mat-label>
      <mat-select matInput   placeholder="Choose Speciality" formControlName="speciality"   autocomplete="off">
      <mat-option>Choose Speciality</mat-option>
      <mat-option  *ngFor="let speciality of specialities" value="{{speciality.id}}">{{speciality.specialtyName}}</mat-option>

      </mat-select>
      <mat-error *ngIf="!caregiverForm.controls['speciality'].valid && caregiverForm.controls['speciality'].touched">
       specaility is required.
      </mat-error>
    </mat-form-field>

  </div>
  <div class="row" style="color: #ffffff;margin-left:0px;margin-right:0px;justify-content: center;">
    <span style=" padding-right:0.8em;font-family: 'Century Gothic';">Send Verification Code To: </span> 
    <mat-checkbox style="font-family: 'Century Gothic' !important;" (change)="radioButton($event)" formControlName="enableEmailNotif" value="email" ngDefaultControl checked>Email</mat-checkbox>
    <mat-checkbox style="font-family: 'Century Gothic' !important;" (change)="radioButton($event)" formControlName="enableMobileNotif" ngDefaultControl value="sms">SMS</mat-checkbox>
  </div>
    <!--<mat-radio-button (change)="radioButton($event)" formControlName="enableEmailNotif" value="email"  ngDefaultControl checked>Email</mat-radio-button>
    <mat-error *ngIf="!caregiverForm.controls['enableEmailNotif'].valid && caregiverForm.controls['enableEmailNotif'].touched">
      confirm for send otc to email
     </mat-error>
        <mat-radio-button (change)="radioButton($event)"  formControlName="enableMobileNotif" ngDefaultControl value="sms">SMS</mat-radio-button>-->

        <ng-container *ngIf="note">

          <div style="padding:1em; text-align: left; color:#ffffff; margin:5px 0px;border-radius: 20px;font-family: 'Century Gothic';">
            
            NOTE : Standard text messaging rates from your wireless carrier may apply
             
          </div>
  
        </ng-container>
  
  
  <div class="row" style="margin-left:0px;margin-right:0px;justify-content: center;"> 
    <button mat-button *ngIf="!isloading" type="submit" class="button" [disabled]="!caregiverForm.valid">REGISTER</button>
    <button *ngIf="isloading"  class="regspinner button"><i  class="fa fa-spinner" style="text-align: center;" aria-hidden="true"></i></button>
  </div>

</form>
</div>
 <!--  Recruiter Registration Form -->
<div style="margin-top:1.5em" id="recruiterSection" *ngIf='recruiter && !caregiver'>

  <form   [formGroup]="recruiterForm"  (ngSubmit)="recruiteronSubmit(recruiterForm.value)"  novalidate >
    <div class="row">
      <div class="col-md-6"><mat-form-field   appearance="outline"> 
        <mat-label style="font-size: 17px;"> First Name *</mat-label>
        <input matInput placeholder="First Name" formControlName="firstname" maxlength="13"  autocomplete="off">
        <mat-error *ngIf="!recruiterForm.controls['firstname'].valid && recruiterForm.controls['firstname'].touched">
          First name is required.
        </mat-error>
      </mat-form-field></div>
      <div class="col-md-6"><mat-form-field   appearance="outline"> 
        <mat-label style="font-size: 17px;"> Last Name *</mat-label>
        <input matInput placeholder="First Name" formControlName="lastname" maxlength="13"  autocomplete="off">
        <mat-error *ngIf="!recruiterForm.controls['lastname'].valid && recruiterForm.controls['lastname'].touched">
         Last name is required.
        </mat-error>
      </mat-form-field></div>
    </div>
    <div class="row">
      <div class="col-md-6"><mat-form-field   appearance="outline"> 
        <mat-label style="font-size: 17px;"> Email *</mat-label>
        <input matInput placeholder="Email" formControlName="username"   autocomplete="off">
        <mat-error *ngIf="!recruiterForm.controls['username'].valid && recruiterForm.controls['username'].touched">
          {{getErrorEmailRecruiter() }}
        </mat-error>
      </mat-form-field></div>
      <div class="col-md-6">
        <mat-form-field   appearance="outline"> 
        <mat-label style="font-size: 17px;"> Phone Number <span *ngIf="isEnableStar1">*</span></mat-label>
        <input matInput placeholder="Phone Number" formControlName="phoneNumber"   autocomplete="off">
        <mat-error style="width:105%;" *ngIf="!recruiterForm.controls['phoneNumber'].valid && recruiterForm.controls['phoneNumber'].touched">
          {{getErrorPhonerecruiter()}}
        </mat-error>
      </mat-form-field></div>
    </div>
    <div class="row" formGroupName ="passwordGroup">
      <div class="col-md-6 mb-3" id="passwordFormat" style="display: flex;">
    <mat-form-field appearance="outline"  class="mat-form-field-outline">
      <mat-label>Password *</mat-label>
      <input matInput placeholder="Password" [type]="hide ? 'text' : 'password'" formControlName="password"   autocomplete="off">
      <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility ' : 'visibility_off'}}</mat-icon>
     
    <mat-error *ngIf="recruiterForm.controls['passwordGroup'].get('password').dirty && !recruiterForm.controls['passwordGroup'].get('password').valid" >
      {{errors.PasswordFormat}}
   </mat-error>
   <mat-error *ngIf="!recruiterForm.controls['passwordGroup'].get('password').dirty && recruiterForm.controls['passwordGroup'].get('password').touched && !recruiterForm.controls['passwordGroup'].get('password').valid" >
    {{errors.Password}}
  </mat-error>
    </mat-form-field>
    <div><i style="padding: 5px;
    color: #ffffff;" class="fa fa-info-circle tip"><span><p style="color:black;font-weight:bold;font-size:16px">Passwords must contain:</p>
  <p style="color:black;">1. Minimum of 8 characters</p>
  <p style="color:rgb(5, 5, 5);">2.one upper case, lower case letter, one special character(!, @, #, $, %, ^, &, *) and one number</p></span></i>
  </div>
    </div>
    <div class="col-md-6 mb-3" style="display: flex;">
    <mat-form-field appearance="outline"  class="mat-form-field-outline">
      <mat-label>Confirm Password *</mat-label>
      <input matInput placeholder="Confirm Password" [type]="hide1 ? 'text' : 'password'" formControlName="confirmPassword" autocomplete="off" [errorStateMatcher]="matcher">
        <mat-icon matSuffix (click)="hide1 = !hide1">{{hide1 ? 'visibility ' : 'visibility_off'}}</mat-icon>
        <mat-error *ngIf="recruiterForm.controls['passwordGroup'].get('password').dirty" >
          {{errors.ConfirmPassword}}
       </mat-error>
       <mat-error *ngIf="!recruiterForm.controls['passwordGroup'].get('password').dirty && recruiterForm.controls['passwordGroup'].get('confirmPassword').touched && !recruiterForm.controls['passwordGroup'].get('password').valid" >
        {{errors.cnfmPassword}}
      </mat-error>
    </mat-form-field>

    </div>
    </div>
    <div class="row" style="margin-left:0px;margin-right:0px">
      <mat-form-field   appearance="outline"> 
        <mat-label style="font-size: 17px;"> Agency Name *</mat-label>
        <input matInput placeholder="Enter Agency Name" formControlName="agencyName"   autocomplete="off">
        <mat-error *ngIf="!recruiterForm.controls['agencyName'].valid && recruiterForm.controls['agencyName'].touched">
         AgencyName is required.
        </mat-error>
      </mat-form-field>
  
    </div>
    <div class="row" style="color: #ffffff;margin-left:0px;margin-right:0px;justify-content: center;">
      <span style=" padding-right:0.8em;font-family: 'Century Gothic';">Send Verification Code To: </span> 
      <mat-checkbox style="font-family: 'Century Gothic' !important;" (change)="radioButtonRec($event)" formControlName="enableEmailNotif" value="email" checked>Email</mat-checkbox>
      <mat-checkbox style="font-family: 'Century Gothic' !important;" (change)="radioButtonRec($event)" formControlName="enableMobileNotif" value="sms">SMS</mat-checkbox>
    </div>
           <ng-container *ngIf="noterec">

        <div style="padding:1em; text-align: left; color:#ffffff; margin:5px 0px;border-radius: 20px;font-family: 'Century Gothic';">
          
          NOTE : Standard text messaging rates from your wireless carrier may apply
           
        </div>

      </ng-container>
    
    <div class="row" style="margin-left:0px;margin-right:0px;justify-content: center;"> 
      <button *ngIf="!isloading" mat-button  type="submit" class="button" [disabled]="!recruiterForm.valid">REGISTER</button>
      <button *ngIf="isloading" class="regspinner button"><i  class="fa fa-spinner" style="text-align: center;" aria-hidden="true"></i></button>
    </div>
  
  </form>
   

</div>
</div>
  <div *ngIf="buttonnClieked"><a class="dashboardLink" (click)="backtoDashboard()" routerLinkActive="active"><i style="margin-right: 5px;" class="fa fa-arrow-circle-left"></i>Back to Registration Dashboard</a></div>
</div>
  <div #appenHere></div>
  <!-- Verification code form -->
  <div *ngIf="validateOtc"  class="container-fluid" >
    <div class="fadeIn first loginHeading" >
      <span>Validate Your Account</span>
     </div>
    <p style="color: white;font-family: 'Century Gothic';font-size: 18px;"  *ngIf="isEmail && !note">Verification code has been sent to <a style="color:#AA004D;"> {{' ' + activationEMail}}</a>.Please check your email and activate your account by validating the verification code.</p>
    <p style="color: white;font-family: 'Century Gothic';font-size: 18px;" *ngIf="note && !isEmail">Verification code has been sent to <a style="color:#AA004D;"> {{' ' + activationMobile}}</a>
       .Please check your text message and activate your account by validating the verification code.</p>
       <p style="color: white;font-family: 'Century Gothic';font-size: 18px;" *ngIf="note && isEmail">Verification code has been sent to <a style="color:#AA004D;"> {{' ' + activationEMail}}</a> and <a style="color:#AA004D;"> {{' ' + activationMobile}}</a> 
        .Please check your email and text message and activate your account by validating the verification code.</p>
   
    <form   [formGroup]="activationForm"  (ngSubmit)="activationSubmit(activationForm.value)"  novalidate >
        <mat-form-field   appearance="outline"> 
    <mat-label style="font-size: 17px;"> Enter Verification code *</mat-label>
    <input matInput placeholder="Enter 4-digit verification code" formControlName="OTC"   autocomplete="off">
    <mat-error *ngIf="!activationForm.controls['OTC'].valid && activationForm.controls['OTC'].touched">
      {{getErrorOtc()}}
    </mat-error>
  </mat-form-field>
  <button mat-button *ngIf="!isloading"  type="submit" class="verifcodebutton" [disabled]="!activationForm.valid">Validate Account </button>
  <button *ngIf="isloading" class="regspinner verifcodebutton"><i  class="fa fa-spinner" style="text-align: center;" aria-hidden="true"></i></button>
  <p style="color: white;font-family: 'Century Gothic';
  font-size: 16px;"> If you do not see an email after 5 minutes, please <a class="resendButton" (click)="reSend()" >click here</a></p>
</form>
    

</div>

</div>
  
</div>
<ng-template #sentOtc>
  <div>
    {{text}}
    </div>
    <div style="text-align:center">
      <button style="outline:none;color:white;width:auto;padding: 5px 16px;" type="button" class="btn btn-primary" mat-dialog-close>OK</button>
      </div>
  </ng-template>
  <ng-template #selectOptions>
    <div>
      <h4 class="modal-title w-100"><i class="fa fa-exclamation-circle" style="margin-right:10px;cursor: auto;"></i>You should select at least one option from Email and SMS</h4>
      </div>
      <div style="text-align:center">
        <button style="outline:none;color:white;width:auto;padding: 5px 16px;" type="button" class="btn btn-primary" mat-dialog-close>OK</button>
        </div>
    </ng-template>


