import { Component, OnInit, ViewChild } from '@angular/core';
import { AdminService } from 'src/app/shared-services/adminservice.service';
import { AgGridAngular } from 'ag-grid-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminActionComponent } from './adminAction.component';
import { CellClickedEvent, GridOptions } from 'ag-grid-community';

@Component({
  selector: 'app-adminMainPage',
  templateUrl: './adminMainPage.component.html',
  styleUrls: ['./adminMainPage.component.scss']
})
export class AdminMainPageComponent implements OnInit {
  public isCaregiverList = true;
  
   gridApi;
 paginationPageSize;
 domLayout;
 public rowClassRules; 
 public loading = true;
 public frameworkComponents;         /** to render action Component */
  rowData =[];
  
  reports = [{
    id: 1, name: 'User Session Report'},
  {
    id: 2, name: 'User Activity Report'},
  {
    id: 3, name: 'Document Tracking'},
  ];
  users = [{
    id: 3, name: 'All'},
  {
    id: 1, name: 'Caregiver'},
  {
    id: 2, name: 'Agency Rep'},
  ];
  @ViewChild("agGrid", {static: false}) agGrid:AgGridAngular;
    constructor(
      private adminService:AdminService,
      private router:Router,
      private route:ActivatedRoute
    ){
      this.rowClassRules ={
        'red-color':function(params){
         return params.data.blockUser === true;
        }
      }
      this.paginationPageSize =10;
      this.domLayout = 'autoHeight';
      this.frameworkComponents = {
        actionRenderer : AdminActionComponent
      };
    }
    ngOnInit() :void {
      this.adminService.getUsersDetails('3').subscribe(userObject=>{
        sessionStorage.setItem('role', '3');
        console.log("userObject", userObject);
        this.rowData = userObject.responseData;
        this.loading = false;
      })
      this.adminService.loadData.subscribe(()=>{  /** refresh to see new uploaded docs */
        this.usersList(sessionStorage.getItem('role'));
      
      });
    }
     usersList(params) {
      sessionStorage.setItem('role', params);
      this.rowData =[];
      if(params == '1'){
        this.gridApi.columnApi.setColumnVisible('agencyName', false);
        this.gridApi.columnApi.setColumnVisible('Role', false);
        this.gridApi.api.sizeColumnsToFit();
      }else if (params == '2'){
        this.gridApi.columnApi.setColumnVisible('Role', false);
        this.gridApi.columnApi.setColumnVisible('agencyName', true);
        this.gridApi.api.sizeColumnsToFit();
      }else{
        this.gridApi.columnApi.setColumnVisible('agencyName', true);
        this.gridApi.columnApi.setColumnVisible('Role', true);
        this.gridApi.api.sizeColumnsToFit();
      }
      
      this.adminService.getUsersDetails(params).subscribe(userObject=>{
        console.log("userObject", userObject);
        this.rowData = userObject.responseData;

        this.loading = false;
      })
    }
    onChange(event){
      if(event == 1){
        this.router.navigateByUrl('/user-session-rep');
    this.router.navigate(['/user-session-rep'], { relativeTo:this.route});
      }else if(event == 2){
        this.router.navigateByUrl('/user-activity-rep');
        this.router.navigate(['/user-activity-rep'], { relativeTo:this.route});
      }else if(event == 3){
        this.router.navigateByUrl('/document-tracking');
        this.router.navigate(['/document-tracking'], { relativeTo:this.route});
      }
      
    }
    columnDefs = [{field:"userId", sortable:true, unSortIcon: true, filter:true,resizable: true,cellStyle: { color: "#2777BA", cursor: "pointer", textDecoration: "none" },
    cellRenderer: (params) => {
      return `<a href="javascript:void(0);" style="color: #2777BA; text-decoration: none;">${params.value}</a>`;
    },
    onCellClicked: (params) => {
      if(params.data.roleId == 1){
        this.router.navigateByUrl('/caregiverDocs');
      this.router.navigate(['/caregiverDocs', params.data.userId], { relativeTo:this.route})
      }else {
        alert("This is not the caregiver");
      }
    console.log(params);
      // Handle navigation or action
    }},
      { field: "firstname", sortable: true, unSortIcon: true, filter:true, resizable: true,}, 
  { field: "lastname", sortable: true, unSortIcon: true, resizable: true,filter:true }, 
  { field: "email",filter:true, sortable: true, resizable: true},
  { field: "phoneNumber",filter:true, sortable: true, resizable: true},
  { field: "agencyName",filter:true, sortable: true, resizable: true},
  { field: "createdDate",filter:true, sortable: true, resizable: true,valueGetter:this.dateformatter },
  { field: "Role", filter:true, sortable: true, resizable: true,valueGetter:this.convertData, valueFormatter: function (params) {
    return params.value; // Return the formatted value for display
  }, cellRenderer:params=>{
    if(params.data.roleId == 1){
      return '<span>Caregiver</span>';
    }else if(params.data.roleId == 2){
    return '<span>Agency Rep</span>';
    }else {
      return '<span>Admin</span>';
    }
  }},
  { field: "Actions", cellRenderer: "actionRenderer", resizable: true }];
  convertData(params){
    if(params.data.roleId == 1){
         return 'Caregiver';
       }else if(params.data.roleId == 2){
       return 'Agency Rep';
       }else{
        return 'Admin';
       }
   }
   onRowDoubleClicked(event){
    let i = event.rowIndex;
    let roleId = this.rowData[i].roleId;
    let nurseID = this.rowData[i].userId;
    if(roleId == 1){
      this.router.navigateByUrl('/caregiverDocs');
    this.router.navigate(['/caregiverDocs', nurseID], { relativeTo:this.route})
    }else {
      alert("This is not the caregiver");
    }
  console.log(event);
   }
  onGridReady(params) {
    
    // params.api.sizeColumnsToFit();
    if(window.matchMedia("(min-width: 1260px)").matches){
     params.api.sizeColumnsToFit();
   }
     window.addEventListener("resize", function () {
       setTimeout(function () {
         if(window.matchMedia("(min-width: 1260px)").matches){
         params.api.sizeColumnsToFit();
       }
       });
     });
   
   this.gridApi = params;
   this.gridApi.api.setColumnDefs(this.columnDefs);
   this.gridApi.api.setRowData(this.rowData); 
   //this.autoSizeAll();
  
  // this.gridApi.setDomLayout('autoHeight');
   //document.getElementById('#myGrid').style.height = '';
 }
 gridOptions: GridOptions = {
  defaultColDef: {
    // sortable: true,
    // filter: true,
    // resizable: true,
    menuTabs: ['filterMenuTab'], // Show only the filter menu
  },
  icons: {
    menu: '<i class="fa fa-search"></i>', // Replace menu icon with a search icon
  },
}

 dateformatter(params){ /** for date format */
var dateSplit;
if(params.data.createdDate  !=null && params.data.createdDate  !=undefined){
  dateSplit = params.data.createdDate .split("T");

// else{
//   dateSplit = params.split("T");
// }
 // var dateSplit = params.value.split("T");
  var dateSplit1 = dateSplit[0].split("-");
  var date = dateSplit1[2];
  var month = dateSplit1[1];
  var year = dateSplit1[0];
  var fullDate = month.concat("/", date, "/", year);
  return fullDate;
}
 // return moment(params.value).format('DD/MM/YYYY'); 
}
}