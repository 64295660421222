import { Injectable } from '@angular/core';
import { API_BASE_URL } from './auth.constant';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProfessionDataService {
  static professionData = API_BASE_URL + '/api/public/professionlookups';
  static specialityData  = API_BASE_URL + '/api/public/specialtylookups/';
  static CAREGIVER_DOCS_NAMES = API_BASE_URL + '/api/private/caregiver/documentNames'

  constructor(private httpClient: HttpClient) { 
    if(localStorage.getItem('jwt') !=null){
      sessionStorage.setItem("access_token", localStorage.getItem('jwt'));
    }
  }
  getProfessions(token: String) {

    const headers = new HttpHeaders()
    .append('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + token);
    
    return this.httpClient.get(ProfessionDataService.professionData, {
        headers: headers
      })
        .pipe(map((res: any) => {
          if (res) {
           return res.professionLookups;
          }
          return null;
        }));
}
/*getDocuments(token: String){
  const headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Authorization', 'Bearer ' + token);
    
    return this.httpClient.get(ProfessionDataService.CAREGIVER_DOCS_NAMES, {
        headers: headers
      })
        .pipe(map((res: any) => {
          if (res) {
            console.log("response "+JSON.stringify(res));
            return res;
          }
          return null;
        }));
}
*/
getSpecialties(registerToken: any, professionId: any) {
    const headers = new HttpHeaders()
    .append('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + registerToken);
    return this.httpClient.get(ProfessionDataService.specialityData + professionId, {
        headers: headers
      })
        .pipe(map((res: any) => {
          if (res) {
            return res.specialtyLookups;
          }
          return null;
        }));
}
}
