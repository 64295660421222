<div class="main-container">
    <!--<h1 class="heading">
    Credentials Management Made Easy For Caregivers and Recruiters</h1>-->
    <!--<div style="background: #095c9e;
    display: flex;">
        <a style="color: white;
        background: #c2185b;
        padding: 10px;
        margin: 10px;
        border-radius: 5px;
        text-decoration: none;" (click)="signUpButton()" [routerLink]="'/login'">LOG IN</a>
        <a style="color: white;
        background: #c2185b;
        padding: 10px;
        margin: 10px;
        border-radius: 5px;
        text-decoration: none;" (click)="signUpButton()" [routerLink]="'/registration'">SIGN UP</a></div>-->
    <div class="sub-container">
        <div class="request-container">
            <div class="img-container">
            <img src="../../../assets/cms_illustration_3.png">
            </div>
            <div class="text-container">
                <p class="heading">REQUEST DOCUMENTS FROM CAREGIVERS</p>
    <p>Recruiter can search caregiver and send request to view or download their documents securely at any time.</p>
                </div>
        </div>
        <div class="accessDoc-container">
            <div class="text-container">
                    <p class="heading">ACCESS CAREGIVER DOCUMENTS</p>
        <p>Recruiters can view or download documents of caregiver securely at anytime 
        when caregiver accepts their request to view or download documents
        </p>
                    </div>
                    <div class="img-container">
                        <img src="../../../assets/cms_illustration_4.png">
                        </div>
            </div>
            </div>
            <div class="signUpLink">
                <a (click)="signUpButton()" [routerLink]="'/login'">LOG IN</a>
                <a (click)="signUpButton()" [routerLink]="'/registration'">SIGN UP</a></div>
            </div>
            <footer style="text-align: center;font-family: 'Century Gothic';">
            
                <div style="display:flex;justify-content: space-around;padding: 10px;background-color: #ebebeb;color: #353535;align-items: center;">
                <div style="display: flex;">
                <div>
                        <i style="font-size: 50px;" class="fa fa-phone" aria-hidden="true"></i>
                    </div>
                  <div style="padding-left: 10px;">
                      <h4 style="margin-bottom: 0px !important;font-size: 18px;">
                        Call Us
                      </h4>
                     <p style="font-size: 16px;"> 855-888-7333</p>
        
                    </div>
                  </div>
                  <div style="display: flex;">
                    <div>
                          <a style="color: #353535" href="mailto:Admin@Gcredentials.com">
                            <i style="font-size: 50px;" class="fa fa-envelope-o" aria-hidden="true"></i>
                          </a>
                        </div>
                      <div style="padding-left:10px">
                          <h4 style="margin-bottom: 0px !important;font-size: 18px;">
                            <a style="color: #353535" href="mailto:Admin@Gcredentials.com">Email Us</a>
                          </h4>
            
            
                          <p>
                            <a style="font-size: 16px;color: #353535" href="mailto:Admin@Gcredentials.com">Admin@Gcredentials.com</a>
                          </p>
                        </div>
            </div>
                      <!-- .et_pb_blurb_content -->
                    <!-- .et_pb_blurb -->
                  <div style="display: flex;">
                    <div>
                    <i style="font-size: 50px;" class="fa fa-map-marker" aria-hidden="true">
                    </i>
                    </div>
                    <div style="padding-left: 10px;">
                    <!--<span style="font-size: 18px;">Address: </span>-->
                    <p style="font-size: 16px;">50 Millstone Road</p>
                    <p style="font-size: 16px;">Building 100, Suite 100</p>
                    <p style="font-size: 16px;">East Windsor NJ 08520</p>
                  </div>
                  </div>
                  </div>
                  <div style="padding-top: 10px;">
                    @Powered by <a href="https://geniehealthjobs.com" target="_blank"><img width="100px" src="../../../assets/GenieLogo.png"></a>
                    </div>
                
                </footer>